<template>
  <nav class="py-3 bg-white shadow-sm">
    <div class="container flex flex-wrap justify-between items-center mx-auto">
      <router-link :to="{
        path: '/',
      }" class="ot-title-1">
        Indimap
      </router-link>   
      <div class="w-full md:block md:w-auto">
        <button v-if="user" @click="logout()"
          class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
          Salir
        </button>
      </div>
    </div>
    <div class="container">
    <span class="text-sm text-base font-bold md:text-base">
        Mapeo de actores relacionados al desarrollo <br />
        de los pueblos indígenas de Panamá
      </span>
    </div>
  </nav>
</template>

<script>
import { firebase } from "@/firebase";
export default {
  name: "NavBar",
  data() {
    return {
      user: null
    }
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      const user = await firebase.getCurrentUser();
      if (user) this.user = user;
    },
    logout() {
      firebase.auth().signOut().then(() => {
        this.$router.push('/login')
      }).catch((error) => {
        console.log("error logout: ", error)
      });
    }
  }
};
</script>

<style scoped>

</style>
