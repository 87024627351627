<template>
  <div>
    <div ref="container" class="donut-container">
      <svg ref="svg" class="overflow-visible print:h-full print:w-full" />
    </div>
    <VTooltip :show="tooltip.show" :followMouse="true">
      <div>
        <div class="font-bold">{{ tooltip.name }}</div>
        <div>{{ tooltip.value.toFixed(1) }}%</div>
      </div>
    </VTooltip>
  </div>
</template>

<script>
// https://observablehq.com/@d3/donut-chart
import * as d3 from "d3";
import VTooltip from "@/components/VTooltip";
export default {
  name: "DonutChart",
  components: {
    VTooltip,
  },
  props: {
    data: {
      type: Array,
      default: () => [
        { name: "Val 1", value: 50, color: "#eeeeee" },
        { name: "Val 2", value: 100, color: "#cccccc" },
      ],
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 500,
    },
    minAngle: {
      type: Number,
      default: 0.1,
    },
    cornerRadius: {
      type: Number,
      default: 3,
    },
    padAngle: {
      type: Number,
      default: 0.02,
    },
    sortValues: {
      type: Boolean,
      default: true,
    },
    labelsOutside: {
      type: Boolean,
      default: false,
    },
    allowTooltip: {
      type: Boolean,
      default: false,
    },
    smallScreen: {
      type: Boolean,
      default: false,
    },
    canClick: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "14px",
    },
    wrapLabels: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      width: 200,
      height: 200,
      hoverIndex: null,
      tooltip: {
        show: false,
        target: null,
        name: "",
        value: 0,
      },
    };
  },
  computed: {
    pie() {
      const pie = d3
        .pie()
        .padAngle(this.padAngle)
        .value((d) => d.value);
      return this.sortValues ? pie : pie.sort(null);
    },
    arcs() {
      return this.pie(this.data);
    },
    arc() {
      const radius = Math.min(this.width, this.height) / 2;
      return d3
        .arc()
        .innerRadius(radius * 0.67)
        .outerRadius(radius - 1)
        .cornerRadius(this.cornerRadius);
    },
    // wider arc generator to position labels outside of donut
    labelArc() {
      const radius = Math.min(this.width, this.height) / 2;
      return d3
        .arc()
        .innerRadius(radius + 20)
        .outerRadius(radius + 20);
    },
  },
  watch: {
    data() {
      this.updateChart();
    },
    selected(newVal) {
      this.svg.selectAll(".arc").each(function(d) {
        if (newVal && d.data.name !== newVal)
          d3.select(this).attr("fill-opacity", 0.5);
        else d3.select(this).attr("fill-opacity", 1);
      });
    },
    wrapLabels() {
      this.updateChart();
    },
  },
  mounted() {
    this.setSize();
    this.createChart();
    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      this.resizeObserver.observe(this.$refs.container);
    } else {
      // for IE
      window.addEventListener("resize", this.resize);
    }
  },
  beforeUnmount() {
    if (window.ResizeObserver) {
      this.resizeObserver.unobserve(this.$refs.container);
    } else {
      // for IE
      window.removeEventListener("resize", this.resize);
    }
  },
  methods: {
    setSize() {
      const container = this.$refs.container;
      if (container) {
        // this.width = container.clientWidth;
        // this.height = container.clientHeight;

        const width = container.clientWidth;
        this.width = width;
        this.height = width;
      }
    },
    resize() {
      const currentWidth = this.width;
      const currentHeight = this.height;
      this.setSize();
      if (currentWidth !== this.width || currentHeight !== this.height) {
        this.updateChart();
      }
    },
    draw() {
      // position svg with point (0,0) in the center
      this.svg.attr(
        "transform",
        `translate(${this.width / 2} ${this.height / 2})`
      );
      const that = this;
      // function label(val, count) {
      //   console.log(val, count);
      //   return `${count} (${val.toFixed(0)}%)`;
      // }
      const displayLabel = (d) => d.endAngle - d.startAngle > this.minAngle;
      function arcTween(a) {
        var i = d3.interpolate(this._current, a);
        this._current = i(0);
        return function(t) {
          return that.arc(i(t));
        };
      }
      function textPositionTween(d) {
        this._current = this._current || d;
        var i = d3.interpolate(this._current, d);
        this._current = i(0);
        return function(t) {
          var d2 = i(t);
          var pos = that.labelsOutside
            ? that.labelArc.centroid(d2)
            : that.arc.centroid(d2);
          return `translate(${pos})`;
        };
      }
      // not working when I try to add count
      // function textTween(d) {
      //   // we access the parentElement because we are tweening the tspan element but the data is in the text element
      //   var i = d3.interpolate(this.parentElement._current.value, d.value);
      //   this.parentElement._current.value = i(0);

      //   // count
      //   var j = d3.interpolate(this.parentElement._current.count, d.count);
      //   this.parentElement._current.count = j(0);

      //   return function(t) {
      //     d3.select(this).text(label(i(t), j(t)));
      //   };
      // }
      this.arcsPath = this.svg
        .selectAll("path")
        .data(this.arcs, (d) => d.data.name)
        .join(
          (enter) =>
            enter
              .append("path")
              .attr("fill", (d) => d.data.color)
              .each(function(d) {
                this._current = { ...d, endAngle: d.startAngle, value: 0 };
              })
              .attr("d", this.arc)
              .attr("class", "arc")
              .attr("cursor", this.canClick ? "pointer" : "default")
              .call((path) =>
                path
                  .transition()
                  .duration(this.duration)
                  // .delay(2*delay)
                  .attrTween("d", arcTween)
              )
              .on("mouseenter", function(e, d) {
                if (this.allowTooltip) that.showTooltip(d.data);
              })
              .on("mouseleave", function() {
                if (this.allowTooltip) that.hideTooltip();
              })
              .on("click", (e, d) => {
                this.$emit("select", { target: e.target, data: d.data });
              }),
          // for accessibility
          // .append("title")
          // .text((d) => `${d.data.name}: ${d.data.value}`),
          (update) => {
            return update.call((path) =>
              path
                .transition()
                .duration(this.duration)
                // .attr("d", this.arc)
                .attrTween("d", arcTween)
            );
          },
          (exit) =>
            exit.call((path) =>
              path
                .each((path) => (path.endAngle = path.startAngle))
                .transition()
                .duration(this.duration)
                .attrTween("d", arcTween)
                .remove()
            )
        );
      this.textsContainer
        .raise()
        .selectAll("text")
        .data(this.arcs, (d) => d.data.name)
        .join(
          (enter) => {
            return (
              enter
                .append("text")
                // .attr("transform", (d) => `translate(${this.arc.centroid(d)})`)
                .attr("text-anchor", (d) => {
                  if (this.labelsOutside) {
                    // flip label of last category when it's too small
                    // otherwise it overlaps with other label
                    if (
                      d.endAngle - d.startAngle < 0.5 &&
                      d.index === this.data.length - 1
                    ) {
                      return "start";
                    } else {
                      // align text left or right depending on which side the label is positioned
                      return this.labelArc.centroid(d)[0] > 0 ? "start" : "end";
                    }
                  } else return "middle";
                })
                .attr("transform", (d) =>
                  // position labels on or outside donut
                  this.labelsOutside
                    ? `translate(${this.labelArc.centroid(d)})`
                    : `translate(${this.arc.centroid(d)})`
                )
                .attr("fill", (d) => d.data.textColor || "black")
                .attr("opacity", 0)
                .attr("font-size", this.fontSize)
                .each(function(d) {
                  this._current = { ...d, endAngle: d.startAngle, value: 0 };
                })
                // display name
                .call((text) =>
                  text
                    .append("tspan")
                    .attr("class", "label")
                    .attr("y", "-0.5em")
                    // .attr("x", 0)
                    // .attr("dy", "-1em")
                    // .attr("font-weight", "bold")
                    .text((d) => {
                      const parts = d.data.name.split(" ");
                      if (parts[0] === "fins" && this.smallScreen) {
                        return parts[2];
                      }
                      return d.data.name;
                    })
                    .style("display", this.showLabels ? null : "none")
                )
                // .call((text) => {
                //   const filtered = text.filter((d) => {
                //     const parts = d.data.name.split(" ");
                //     return parts[0] === "fins" && this.smallScreen;
                //   });
                //   return (
                //     filtered
                //       .append("tspan")
                //       .attr("class", "")
                //       .attr("x", 0)
                //       .attr("dy", "-1em")
                //       // .attr("font-weight", "bold")
                //       .text(
                //         (d) =>
                //           `${d.data.name.split(" ")[0]} ${
                //             d.data.name.split(" ")[1]
                //           }`
                //       )
                //       .style("display", this.showLabels ? null : "none")
                //   );
                // })
                // display percentage
                .call((text) =>
                  text
                    .append("tspan")
                    .attr("class", "value")
                    .attr("x", 0)
                    .attr("y", "0.5em")
                    .attr("fill-opacity", 0.7)
                    // .text(label(0, 0))
                    .text(
                      (d) => `${d.data.count} (${d.data.value.toFixed(0)}%)`
                    )
                    .transition()
                    .duration(this.duration)
                    // .delay(2*delay)
                    // .tween("text", textTween)
                    .style("display", this.showLabels ? null : "none")
                )
                // display count
                // .call((text) =>
                //   text
                //     .append("tspan")
                //     .attr("class", "count")
                //     .attr("x", "0")
                //     .attr("dy", (d) =>
                //       this.smallScreen && d.data.name.split(" ")[0] === "fins"
                //         ? "2em"
                //         : "1em"
                //     )
                //     .text((d) =>
                //       new Intl.NumberFormat("ca").format(d.data.count)
                //     )
                //     .style("display", this.showLabels ? null : "none")
                // )
                .call((text) =>
                  text
                    .transition()
                    .duration(this.duration)
                    // .delay(2*delay)
                    .attrTween("transform", textPositionTween)
                    .attr("opacity", (d) =>
                      displayLabel(d) || this.labelsOutside ? "1" : "0"
                    )
                    .attr("display", (d) =>
                      displayLabel(d) || this.labelsOutside ? "block" : "none"
                    )
                )
              // .on("mouseenter", function (e, d) {
              //   that.showTooltip(d.data);
              // })
              // .on("mouseleave", function () {
              //   that.hideTooltip();
              // })
            );
          },
          (update) => {
            return update.call(
              (text) =>
                text
                  .attr("font-size", this.fontSize)
                  .transition()
                  .duration(this.duration)
                  // .delay(delay)
                  .attrTween("transform", textPositionTween)
                  .attr("opacity", (d) =>
                    displayLabel(d) || this.labelsOutside ? "1" : "0"
                  )
                  .attr("display", (d) =>
                    displayLabel(d) || this.labelsOutside ? "block" : "none"
                  )
                  .attr("text-anchor", (d) => {
                    if (this.labelsOutside) {
                      // flip label of last category when it's too small
                      // otherwise it overlaps with other label
                      if (
                        d.endAngle - d.startAngle < 0.5 &&
                        d.index === this.data.length - 1
                      ) {
                        return "start";
                      } else {
                        // align text left or right depending on which side the label is positioned
                        return this.labelArc.centroid(d)[0] > 0
                          ? "start"
                          : "end";
                      }
                    } else return "middle";
                  })
                  .select(".value")
                  .text((d) => `${d.data.count} (${d.data.value.toFixed(0)}%)`)
                  .style("display", this.showLabels ? null : "none")
              // .tween("text", textTween)
            );
            // .call((text) =>
            //   text
            //     .select(".count")
            //     .text((d) => new Intl.NumberFormat("ca").format(d.data.count))
            //     .style("display", this.showLabels ? null : "none")
            // );
          },
          (exit) =>
            exit.call(
              (text) =>
                text
                  .each((text) => {
                    text.endAngle = text.startAngle;
                    text.value = 0;
                  })
                  .transition()
                  .duration(this.duration)
                  .attr("opacity", 0)
                  .attrTween("transform", textPositionTween)
                  .remove()
                  // .select(".value")
                  // .tween("text", textTween)
                  .select(".value")
              // .tween("text", textTween)
            )
        );

      if (this.wrapLabels) {
        const labels = this.textsContainer.selectAll("text");

        labels.each(function(d) {
          const textEl = d3.select(this);
          const tspanLabel = textEl.select(".label");

          const labelText = d.data.name;

          // store width of label
          const width = tspanLabel.node().getBoundingClientRect().width;

          // if label is too wide, wrap it
          if (width > 90) {
            // cut label in words
            const parts = labelText.split(" ");

            if (parts.length > 1) {
              // store first part of label in original tspan
              tspanLabel.attr("y", "-1em").text(parts[0]);

              // store rest of the label in new tspan
              textEl
                .append("tspan")
                .attr("x", 0)
                .attr("y", 0)
                .text(parts.slice(1).join(" "));

              // move value label lower
              textEl.select(".value").attr("y", "1em");
            }
          }
        });
      }
    },
    updateChart() {
      d3.select(this.$refs.svg).attr(
        "viewBox",
        `0 0 ${this.width} ${this.height}`
      );
      this.draw();
    },
    createChart() {
      if (!this.svg) {
        this.svg = d3
          .select(this.$refs.svg)
          .attr("viewBox", `0 0 ${this.width} ${this.height}`)
          .append("g");

        this.textsContainer = this.svg
          .append("g")
          .attr("font-family", null)
          .attr("font-size", this.fontSize)
          .attr("text-anchor", "middle");
        this.draw();
      }
    },
    showTooltip(data) {
      this.tooltip.name = data.name;
      this.tooltip.value = data.value;
      this.tooltip.show = true;
    },
    hideTooltip() {
      this.tooltip.show = false;
    },
  },
};
</script>

<style></style>
