<template>
    <!--
      This example requires updating your template:
  
      ```
      <html class="h-full bg-gray-50">
      <body class="h-full">
      ```
    -->
    <div class="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div class="w-full h-full max-w-md space-y-8">
        <div>
          <img class="mx-auto h-12 w-auto" src="@/assets/img/logoDP.png" alt="DevPoles logo" />
          <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Iniciar Sesión</h2>
          <!-- <p class="mt-2 text-center text-sm text-gray-600">
            Or
            {{ ' ' }}
            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">start your 14-day free trial</a>
          </p> -->
        </div>
        <form class="mt-8 space-y-6" @submit.prevent="login()">
          <input type="hidden" name="remember" value="true" />
          <div class="-space-y-px rounded-md shadow-sm">
            <div>
              <label for="email-address" class="sr-only">Correo Electrónico</label>
              <input v-model="email" id="email-address" name="email" type="email" autocomplete="email" required="" class="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="Correo Electrónico" />
            </div>
            <div>
              <label for="password" class="sr-only">Contraseña</label>
              <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required="" class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="Contraseña" />
            </div>
          </div>
  
          <!-- <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
              <label for="remember-me" class="ml-2 block text-sm text-gray-900">Remember me</label>
            </div>
  
            <div class="text-sm">
              <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Forgot your password?</a>
            </div>
          </div> -->
  
          <div>
            <button type="submit" class="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <!-- <LockClosedIcon class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" /> -->
              </span>
              Ingresar
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>

<script>
import firebase from "firebase"

export default {
 name: "Login",
 data() {
  return {
    email:'',
    password: '',
  }
 },
 methods: {
  login() {
    firebase
    .auth().
  signInWithEmailAndPassword(this.email, this.password)
  .then((userCredential) => {
    console.log("signed in") 
    const user = userCredential.user;
    console.log("user: ", user)
    // ...
  })
  .then(() => {
    this.$router.push('/')
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("errorCode: ", errorCode);
    console.log("errorMessage: ", errorMessage);
  });

  }
 }
}
</script>