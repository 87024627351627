<template>
  <div id="app" class="text-gray-800">
    <NavBar v-if="!$route.meta.hideNavbar"/>
    <router-view />
    <TheFooter v-if="!$route.meta.hideFooter"/>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import TheFooter from "@/components/TheFooter";
export default {
  components: {
    NavBar,
    TheFooter,
  },
};
</script>

<style></style>
