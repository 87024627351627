// mapping between subindicator values and categories
export const categories = {
  "Reconocimiento consuetudinario indígena": {
    30: "Sí",
    0: "No",
  },
  "Reconocimiento por norma del Estado": {
    30: "Sí",
    0: "No",
  },
  "Electo en proceso democrático": {
    30: "Sí",
    0: "No",
  },
  "Nivel de cooperación requerida para el éxito de iniciativas relacionadas a sus competencias en el territorio": {
    30: "Indispensable",
    15: "Deseable",
    0: "No necesario",
  },
};

export const actorFunctions = {
  "Autoridad tradicional": {
    name: "Autoridad tradicional",
    color: "#BC24D3",
  },
  "Actor de gobierno local indígena": {
    name: "Gobierno local indígena",
    color: "#D51990",
  },
  "Autoridad del Estado": {
    name: "Autoridad del Estado",
    color: "#CBEC46",
  },
  "Actor político": {
    name: "Político",
    color: "#84D138",
  },
  "Actor de sociedad civil": {
    name: "Sociedad civil",
    color: "#379B9B",
  },
  "Actor económicos": {
    name: "Económicos",
    color: "#8BC7F7",
  },
  "Técnicos": {
    name: "Técnicos",
    color: "#8BO7F7",
  },
};
