import Vue from "vue";
import VueRouter from "vue-router";
import { firebase } from "@/firebase";
import Home from "../views/Home.vue";
import Characteristics from "../views/Characteristics.vue";
import Analysis from "../views/Analysis.vue";
import Actores from "../views/Actores.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: Login,
    name: "login",
    meta: {
      hideNavbar: true,
      hideFooter: true,
    }
  },
  {
    path: "/:terId?",
    component: Home,
    meta: { requiresAuth: true},
    children: [
      { path: "", component: Characteristics, name: "" },
      {
        path: "analisis",
        component: Analysis,
        name: "analisis",
      },
      {
        path: "actores/:actorId?",
        component: Actores,
        name: "actores",
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  // scrollBehavior(to, from) {
  // if (to.name === "Actor" && from.name === "Home") {
  //   return { x: 0, y: 0 };
  // }
  // },
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !await firebase.getCurrentUser()) {
    next('login');
  } else {
    next();
  }
})

export default router;
