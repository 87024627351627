<template>
  <div class="relative">
    <span
      :class="['switch-selected-' + currentItem, sliderClass]"
      :style="{ transform: `translateX(${left}px)`, width: `${width}px` }"
    ></span>

    <div class="relative" :class="[innerContainerClass]" ref="items-container">
      <button
        v-for="(item, i) in internalOptions"
        :key="i"
        :ref="item.value"
        :class="[
          'switch-item-' + i,
          { [itemActiveClass]: item.value == value },
          itemClass,
        ]"
        @click="select(item, $event)"
      >
        {{ item.name }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    itemClass: {
      type: String,
      default:
        "text-gray-500 rounded-full hover:text-gray-600 transition font-medium py-2 px-4 bg-transparent focus:outline-none",
    },
    itemActiveClass: {
      type: String,
      default: "text-gray-600",
    },
    roundedStyle: {
      type: String,
      default: "rounded-full",
    },
    sliderClass: {
      type: String,
      default:
        "absolute inset-y-0 my-1 w-full bg-white shadow-md rounded-full ease-out transition-all duration-500 switch-selector",
    },
    innerContainerClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      width: 0,
      left: 0,
    };
  },
  computed: {
    // return selected button value
    currentItem() {
      return this.internalOptions.findIndex((e) => e.value === this.value);
    },
    // format options data structure
    internalOptions() {
      return this.options.map((e) => {
        if (typeof e === "object") return e;
        return {
          value: e,
          name: e,
        };
      });
    },
  },
  watch: {
    // switch button to new position when value changes
    value() {
      this.setPosition();
    },
  },
  mounted() {
    this.setPosition();
    // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
    if (ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        this.setPosition();
      });
      this.resizeObserver.observe(this.$refs["items-container"]);
    } else {
      // for IE
      window.addEventListener("resize", this.setPosition);
    }
  },
  // for Vue 3
  beforeUnmount() {
    this.removeResizeObserver();
  },
  // for Vue 2
  beforeDestroy() {
    this.removeResizeObserver();
  },
  methods: {
    removeResizeObserver() {
      if (ResizeObserver) {
        this.resizeObserver.unobserve(this.$refs["items-container"]);
      } else {
        // for IE
        window.removeEventListener("resize", this.setPosition);
      }
    },
    // set position of active button based on selected value
    select(item, event) {
      this.width = event.target.getBoundingClientRect().width;
      this.left = event.target.offsetLeft;
      this.$emit("input", item.value);
    },
    // set position of active button based on given value
    setPosition() {
      this.$nextTick(function() {
        this.width = this.$refs[this.value][0].getBoundingClientRect().width;
        this.left = this.$refs[this.value][0].offsetLeft;
      });
    },
  },
};
</script>
