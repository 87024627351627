<template>
  <div ref="container" class="flex justify-center">
    <svg ref="map" :width="width" :height="height" class=""></svg>
    <VTooltip :show="tooltip.show" :followMouse="true">
      <!-- <div class="font-semibold">{{ tooltip.name }}</div>
      <div>{{ tooltip.value }}</div> -->
      <slot :tooltip="tooltip"></slot>
    </VTooltip>
  </div>
</template>

<script>
// import indimap from "@/assets/data/panama_indimap.json"; // topojson indigenous regions
import * as d3 from "d3";
import * as topojson from "topojson-client";
import VTooltip from "@/components/VTooltip";

import api from "@/services/data";

export default {
  name: "PanamaMap",
  components: {
    VTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    colors: {
      type: Object,
      default: () => ({ min: "#E0F2FE", max: "#0C4A6E" }),
    },
    id: {
      type: String,
      default: "",
    },
    strokeColor: {
      type: String,
      default: "#1f2937",
    },
    color: {
      type: String,
      default: "#3F606D",
    },
    colorScale: {
      type: Function,
      default: () => {},
    },
    hovered: {
      type: String,
      default: null,
    },
    selected: {
      type: String,
      default: null,
    },
    noDataColor: {
      type: String,
      default: "#E4E7EB",
    },
    highlightColor: {
      type: String,
      default: "black",
    },
    adjustSize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      width: 1000,
      height: 500,
      loading: false,
      error: false,
      tooltip: {
        show: false,
        name: "",
        data: {},
      },
    };
  },
  methods: {
    draw() {
      const indimap = this.$options.map;

      // console.log(
      //   new Set(
      //     indimap.objects.panama_indimap.geometries
      //       .map((e) => e.properties.Name)
      //       .filter((e) => e)
      //   )
      // );

      // convert topoJson to geoJson
      this.featureCollection = topojson.feature(
        indimap,
        indimap.objects.panama_indimap
      );

      // this map will adapt to its container size based on the WIDTH
      // the aspect ratio of the map will be calculated based on the BOUNDS, and the HEIGHT will be set
      // const projection = d3.geoMercator();
      // .scale(1)
      // .translate([0, 0]);

      // use geoIdentity to scale without transform (because data is already projected)
      // https://stackoverflow.com/questions/42430361/scaling-d3-v4-map-to-fit-svg-or-at-all
      const projection = d3
        .geoIdentity()
        .reflectY(true) // flip y axis
        // .fitSize([this.width, this.height], this.featureCollection);
        .scale(1)
        .translate([0, 0]);

      const path = d3.geoPath().projection(projection);

      // calculate the bounds of the map
      const b = path.bounds(this.featureCollection);

      if (!this.adjustSize) {
        // DO NOT DO THIS if you want to be limited by the height/width of the container
        // DO THIS only if you want the WIDTH of the container to determine the final size of the map
        //calculate aspect ratio
        const aspectRatio = (b[1][0] - b[0][0]) / (b[1][1] - b[0][1]);
        //set height based on aspect ratio
        this.height = this.width / aspectRatio;
        // END of the scary "DO NOT DO THIS"
      }

      // set the viewBox
      this.svg = d3
        .select(this.$refs.map)
        .attr("viewBox", `0 0 ${this.width} ${this.height}`);
      // some math to calculate the scale in function of the bounds and the width/height
      const s =
        1 /
        Math.max(
          (b[1][0] - b[0][0]) / this.width,
          (b[1][1] - b[0][1]) / this.height
        );
      // more math for centering the map...
      const t = [
        (this.width - s * (b[1][0] + b[0][0])) / 2,
        (this.height - s * (b[1][1] + b[0][1])) / 2,
      ];
      // and finally scale and translate the projection
      projection.scale(s).translate(t);

      // region borders
      this.regions = this.mapContainer
        .selectAll("path")
        .data(this.featureCollection.features)
        .join("path")
        .attr("class", (d) => d.properties.id)
        .attr("d", path)
        .attr("stroke", this.strokeColor)
        .attr("stroke-width", 0.8)
        .attr("fill", this.noDataColor)
        .attr("cursor", (d) => (d.properties.id ? "pointer" : "default"));

      this.updateColors();

      this.regions
        .on("mouseover", (e, d) => {
          this.showTooltip(d.properties);
          this.$emit("hover", d.properties.id);
        })
        .on("mouseout", () => {
          this.hideTooltip();
          this.$emit("hover", null);
        })
        .on("click", (e, d) => {
          this.$emit("select", d.properties.id);
        });
    },
    createMap() {
      // remove all shapes in case we are painting a new map
      d3.select(this.$refs.map)
        .selectAll("g")
        .remove();

      this.mapContainer = d3.select(this.$refs.map).append("g");

      this.draw();
    },
    updateColors() {
      if (!this.error && this.regions) {
        this.regions
          .transition()
          .duration(200)
          .attr("fill", (d) => this.colorScale(this.data[d.properties.id]));

        if (this.selected) this.highlight();
      }
    },
    highlight() {
      // reset previously highlighted to normal color
      // if (this.highlighted) this.highlighted.attr("stroke-width", 0.8);
      // if (this.selected) {
      //   // highlight selected territory
      //   this.highlighted = d3
      //     .selectAll(`.${this.selected}`)
      //     .attr("stroke-width", 1.8)
      //     .raise();
      // }
    },
    showTooltip({ name, id }) {
      if (name && id) {
        this.tooltip.name = name;
        this.tooltip.id = id;
        this.tooltip.value = this.data[id];
        this.tooltip.show = true;
      }
    },
    hideTooltip() {
      this.tooltip.show = false;
    },
    setSize() {
      const container = this.$refs.container;
      if (container && !this.error) {
        this.width = container.clientWidth;
        this.height = container.clientHeight;
      }
    },
    resize() {
      const currentWidth = this.width;
      const currentHeight = this.height;
      this.setSize();
      if (currentWidth !== this.width || currentHeight !== this.height) {
        if (this.svg) this.updateMap();
      }
    },
    updateMap() {
      if (!this.error) {
        this.draw();
        this.updateColors();
      }
    },
    async loadTopojson() {
      try {
        this.loading = true;
        this.error = false;
        this.$options.map = await api.getMap();
        this.createMap();
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.setSize();
    this.loadTopojson();

    if (ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      this.resizeObserver.observe(this.$refs.container);
    } else {
      // for IE
      window.addEventListener("resize", this.resize);
    }
  },
  beforeDestroy() {
    if (ResizeObserver) {
      this.resizeObserver.unobserve(this.$refs.container);
    } else {
      // for IE
      window.removeEventListener("resize", this.resize);
    }
  },
  watch: {
    selected() {
      this.highlight();
    },
    hovered(newVal, prevVal) {
      // return to normal
      d3.selectAll(`.${prevVal}`)
        .attr("stroke-width", 0.8)
        .raise();

      // highlight
      d3.selectAll(`.${newVal}`)
        .attr("stroke-width", 1.8)
        .raise();
    },
    data() {
      this.updateColors();
    },
  },
};
</script>

<style scoped></style>
