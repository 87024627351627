<template>
  <div class="mb-20 lg:mb-40">
    <!-- map section -->
    <div class="bg-gray-50">
      <div class="container py-3">
        <div class="flex flex-col md:flex-row md:justify-between items-center">
          <!-- territory selector -->
          <div class="row mt-3">
            <div class="text-gray-800 ">Seleccione el territorio o haga clic en el mapa</div>
            <SearchSelect
              :value="terId"
              :options="terOptions"
              @input="routeTerritory($event, true)"
              default-option-name="Selecciona un territorio"
              all-options-name="Todos los territorios"
              :show-all-options="true"
              main-class="font-bold"
              class="rounded border border-gray-800 md:w-96 text-gray-800 bg-white"
            />
          </div>         

          <!-- indicator selector -->
          <div
            class="mt-3 md:my-6 flex flex-col items-end bg-white py-2 md:py-3 px-3 md:px-6 rounded"
          >
            <div class="flex flex-col items-center">
              <select
                v-model="selectedIndicator"
                id="indicator"
                class="py-1 px-2 md:mb-1"
              >
                <option
                  v-for="indicator in indicators"
                  :key="indicator.id"
                  :value="indicator.id"
                  >{{ indicator.name }}</option
                >
              </select>
              <LegendColor
                :color="mapColorScale"
                :tick-format="(d) => `${d}`"
                class="w-full md:w-72"
              />
            </div>
          </div>
        </div>

        <PanamaMap
          :data="mapData"
          @select="routeTerritory($event, true, 'map')"
          @hover="hoveredTerritory = $event"
          :selected="terId"
          :hovered="hoveredTerritory"
          :colorScale="mapColorScale"
          class=""
          :style="{ height: '400px' }"
          v-slot="{ tooltip }"
        >
          <div v-if="tooltip.id" class="text-sm">
            <div class="font-semibold mb-1">{{ tooltip.name }}</div>
            <div>
              Número de actores: {{ getMapTooltipValues(tooltip.id).actors }}
            </div>
            <div>
              Número de proyectos:
              {{ getMapTooltipValues(tooltip.id).projects }}
            </div>
          </div>
        </PanamaMap>
      </div>
    </div>

    <!-- sticky nav bar -->
    <div
      class="sticky top-0 z-30 bg-gray-50 py-px"
      :class="{ 'border-b-2': showBorderTerritory }"
      id="territory-nav"
      ref="terNav"
    >
      <div class="container">
        <!-- territory selector -->
        <SearchSelect
          :value="terId"
          :options="terOptions"
          @input="routeTerritory"
          :default-option-name="`Todos los territorios`"
          :all-options-name="`Todos los territorios`"
          :show-all-options="true"
          main-class="font-bold"
          style="width:fit-content"
          class="text-gray-800 text-xl md:text-2xl font-bold bg-gray-50 -ml-3"
        />
        <!-- tabs -->
        <div v-if="terId" class="hidden sm:block py-1 mt-0.5">
          <button
            class="ot-tab rounded-l-md"
            :class="{ 'ot-tab-selected': $route.name === '' }"
            @click="routeTab('')"
          >
            Características
          </button>
          <button
            class="ot-tab"
            :class="{ 'ot-tab-selected': $route.name === 'analisis' }"
            @click="routeTab('analisis')"
          >
            Análisis comparativo
          </button>
          <button
            class="ot-tab rounded-r-md"
            :class="{ 'ot-tab-selected': $route.name === 'actores' }"
            @click="routeTab('actores')"
          >
            Análisis por actor
          </button>
        </div>

        <!-- select view for mobile -->
        <div v-if="terId" class="sm:hidden mt-0.5 py-1">
          <select
            class="border px-2 py-1 border-gray-300 rounded"
            :value="$route.name"
            @change="routeTab($event.target.value)"
          >
            <option value="">Características</option>
            <option value="analisis">Análisis comparativo</option>
            <option value="actores">Análisis por actor</option>
          </select>
        </div>
      </div>
    </div>

    <!-- donut charts -->
    <div id="donuts" class="container">
      <div
        class="grid grid-cols-1 gap-y-16 mt-8 md:grid-cols-2 gap-x-16 xl:gap-x-8 items-stretch mb-16 lg:mb-28"
      >
        <!-- type -->
        <div class="flex flex-col justify-between">
          <div class="mb-12 font-bold">Tipo de actores mapeados</div>
          <div v-if="donutTypeActors.length" class="relative">
            <DonutChart
              class="donut"
              :data="donutTypeActors"
              :labels-outside="true"
            />
            <div
              v-show="actors.length"
              class="absolute inset-0 flex flex-col justify-center items-center text-lg"
            >
              <span>{{ terActors.length }}</span>
              <!-- <span class="text-gray-500"
                >{{
                  Math.round((terActors.length / actors.length) * 100)
                }}%</span
              > -->
            </div>
          </div>
          <div v-else>-</div>
        </div>

        <!-- actors -->
        <div ref="donutContainer" class="flex flex-col justify-between">
          <div class="mb-12 font-bold">Actores por función</div>
          <div class="relative">
            <DonutChart
              :data="donutActors"
              :show-labels="true"
              :labels-outside="true"
              :sort-values="false"
              :font-size="smallScreen ? '8px' : '10px'"
              :wrap-labels="smallScreen ? true : false"
              :pad-angle="0.1"
              class="donut"
            />
          </div>
        </div>

        <!-- projects -->
        <div class="flex flex-col justify-between">
          <div class="mb-8 font-bold">Total de proyectos activos</div>
          <div class="relative">
            <DonutChart
              class="donut"
              :data="donutProjects"
              :show-labels="false"
              :show-values="false"
              :sort-values="false"
            />
            <div
              v-show="projects.length"
              class="absolute inset-0 flex flex-col justify-center items-center text-lg"
            >
              <span>{{ terProjects.length }}</span>
              <span class="text-gray-500"
                >{{
                  Math.round((terProjects.length / projects.length) * 100)
                }}%</span
              >
            </div>
          </div>
        </div>

        <!-- operadores -->
        <div class="flex flex-col justify-between">
          <div class="mb-8 font-bold">Total de operadores únicos activos</div>
          <div class="relative">
            <DonutChart
              class="donut"
              :data="donutOrgs"
              :show-labels="false"
              :show-values="false"
              :sort-values="false"
            />
            <div
              v-show="organizations.length"
              class="absolute inset-0 flex flex-col justify-center items-center text-lg"
            >
              <span>{{ terOrgs.length }}</span>
              <span class="text-gray-500"
                >{{
                  Math.round((terOrgs.length / organizations.length) * 100)
                }}%</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-view v-if="terId"></router-view>

    <!-- modal opens when TER11 on map is clicked  -->
    <TwModal :is-open="showModal" @close="showModal = false">
      <span slot="header"
        >Esta zona del mapa contiene la Comarca Ngäbe-Buglé y el Territorio
        Buglé. Haga click para ver su información:</span
      >
      <div slot="body">
        <ul class="list-disc list-inside">
          <li>
            <router-link
              class="ot-link"
              @click.native="closeMapModal"
              :to="{ path: `/TER11/${$route.name}` }"
              >Comarca Ngäbe-Buglé</router-link
            >
          </li>
          <li>
            <router-link
              class="ot-link"
              @click.native="closeMapModal"
              :to="{ path: `/TER12/${$route.name}` }"
              >Territorio Buglé</router-link
            >
          </li>
        </ul>
      </div>
    </TwModal>
  </div>
</template>

<script>
import PanamaMap from "@/components/PanamaMap";
import SearchSelect from "@/components/SearchSelect";
import DonutChart from "@/components/DonutChart";
import LegendColor from "@/components/LegendColor";
import TwModal from "@/components/TwModal";

import * as d3 from "d3";

import api from "@/services/data";
import colors from "@/utils/colors";
import * as IDS from "@/utils/ids";
import { groupBy } from "@/utils/helpers";
import { actorFunctions } from "@/utils/mapping";

// map indicators
const indicators = [
  { id: "actors", name: "Número de actores por territorio" },
  { id: "projects", name: "Número de proyectos por territorio" },
];

export default {
  name: "Home",
  components: {
    PanamaMap,
    SearchSelect,
    DonutChart,
    LegendColor,
    TwModal,
  },
  data() {
    return {
      actors: [],
      territories: [],
      projects: [],
      organizations: [],
      selectedIndicator: "actors",
      indicators,
      mapColors: {
        min: colors.gradientMin,
        max: colors.gradientMax,
      },
      grayColor: "#E5E7EB",
      legitimidad: "0-30",
      poder: "0-30",
      influencia: "0-30",
      credibilidad: "0-30",
      movilizar: "0-30",
      hoveredTerritory: null,
      orderBy: "Legitimidad",
      orderDir: false,
      showBorderTerritory: false,
      scrolled: false,
      showModal: false,
      smallScreen: false,
    };
  },
  computed: {
    // territory param
    terId() {
      return this.$route.params.terId || null;
    },
    // territory options for select
    terOptions() {
      return this.territories.map((ter) => {
        return {
          id: ter[IDS.TER.ID],
          name: ter[IDS.TER.NAME],
          value: ter[IDS.TER.ID],
        };
      });
    },
    // actors filtered by selected territory
    terActors() {
      return this.terId
        ? this.actors.filter((e) => this.terId === e[IDS.AC.TER])
        : this.actors;
    },
    // projects filtered by selected territory
    terProjects() {
      return this.terId
        ? this.projects.filter((e) => this.terId === e[IDS.PROJECT.TER])
        : this.projects;
    },
    // organizations filtered by selected territory
    terOrgs() {
      return this.terId
        ? [...new Set(this.terProjects.map((e) => e[IDS.PROJECT.ORG]))]
        : this.organizations;
    },
    // percentage of actors in selected territory
    donutActors() {
      const grouped = groupBy(this.terActors, IDS.AC.FUNCTION);

      return Object.keys(grouped)
        .map((func) => {
          return {
            name: actorFunctions[func].name,
            value: (grouped[func].length / this.terActors.length) * 100,
            count: grouped[func].length,
            color: actorFunctions[func].color,
          };
        })
        .sort((a, b) => b.value - a.value);
    },
    // types of actors in selected territory
    donutTypeActors() {
      const intern = this.terActors.filter((e) => e[IDS.AC.TYPE] === "Interno")
        .length;
      const extern = this.terActors.filter((e) => e[IDS.AC.TYPE] === "Externo")
        .length;

      return [
        {
          name: "Interno",
          value: (intern / this.terActors.length) * 100,
          count: intern,
          color: colors.intern,
        },
        {
          name: "Externo",
          value: (extern / this.terActors.length) * 100,
          count: extern,
          color: colors.extern,
        },
      ].filter((e) => e.value);
    },
    // percentage projects in selected territory
    donutProjects() {
      const value = (this.terProjects.length / this.projects.length) * 100;
      const rest = 100 - value;

      return [
        {
          name: "projects",
          value,
          color: colors.primary,
        },
        {
          name: "-",
          value: rest,
          color: colors.grey,
        },
      ].filter((e) => e.value);
    },
    // percentage organizations in selected territory
    donutOrgs() {
      const value = (this.terOrgs.length / this.organizations.length) * 100;
      const rest = 100 - value;

      return [
        {
          name: "orgs",
          value,
          color: colors.primary,
        },
        {
          name: "-",
          value: rest,
          color: colors.grey,
        },
      ].filter((e) => e.value);
    },
    groupedActors() {
      return groupBy(this.actors, IDS.AC.TER);
    },
    groupedProjects() {
      return groupBy(this.projects, IDS.PROJECT.TER);
    },
    mapData() {
      // group by selected indicator
      const grouped =
        this.selectedIndicator === "actors"
          ? this.groupedActors
          : this.groupedProjects;

      const result = {};
      this.territories.forEach((ter) => {
        result[ter[IDS.TER.ID]] = grouped[ter[IDS.TER.ID]]
          ? grouped[ter[IDS.TER.ID]].length
          : 0;
      });
      return result;
    },
    mapColorScale() {
      const interpolator = d3.interpolateRgb(
        this.mapColors.min,
        this.mapColors.max
      );

      const values = Object.values(this.mapData);

      return d3
        .scaleSequential()
        .domain([Math.min(...values), Math.max(...values)])
        .interpolator(interpolator)
        .unknown("#E4E7EB");
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.error = false;

        this.actors = await api.getActors();
        this.territories = await api.getTerritories();
        this.projects = await api.getProjects();
        this.organizations = [
          ...new Set(this.projects.map((e) => e[IDS.PROJECT.ORG])),
        ];
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    routeTerritory(terId, scroll = false, target = null) {
      if (terId === "TER11" && target === "map") {
        this.showModal = true;
      } else if (terId && terId !== this.terId) {
        this.$router.push({
          path: `/${terId}/${this.$route.name}`,
        });

        // scroll down automatically
        if (scroll) this.scrollTo("terNav");
      } else {
        this.$router.push({
          path: `/`,
        });
      }
    },
    routeTab(tab) {
      this.$router.push({
        path: `/${this.terId}/${tab}`,
      });
    },
    styleHeader() {
      const territorySelector = document.querySelector("#territory-nav");

      if (territorySelector) {
        if (territorySelector.getBoundingClientRect().top === 0)
          this.showBorderTerritory = true;
        else this.showBorderTerritory = false;
      }
    },
    scrollTo(ref, smooth = true) {
      const el = this.$refs[ref];

      el.scrollIntoView({
        behavior: smooth ? "smooth" : "auto",
        block: "start",
        inline: "nearest",
      });
    },
    closeMapModal() {
      this.showModal = false;
      this.scrollTo("terNav");
    },
    getMapTooltipValues(terId) {
      return {
        actors: this.groupedActors[terId].length,
        projects: this.groupedProjects[terId].length,
      };
    },
    setSize() {
      // store width of 'actors by function' donut to wrap labels on small screens
      const width = this.$refs.donutContainer.getBoundingClientRect().width;

      if (width < 500) this.smallScreen = true;
      else this.smallScreen = false;
    },
  },
  mounted() {
    this.loadData();

    this.setSize();

    window.addEventListener("scroll", this.styleHeader);

    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        this.setSize();
      });
      this.resizeObserver.observe(this.$refs.donutContainer);
    } else {
      // for IE
      window.addEventListener("resize", this.setSize);
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.styleHeader);

    if (window.ResizeObserver) {
      this.resizeObserver.unobserve(this.$refs.donutContainer);
    } else {
      // for IE
      window.removeEventListener("resize", this.setSize);
    }
  },
};
</script>

<style scoped>
.donut {
  @apply w-32 h-32 md:w-40 md:h-40 lg:w-44 lg:h-44 xl:w-48 xl:h-48 mx-auto;
}
</style>
