<template>
  <transition-group name="flip-list-delay" tag="div" class="" appear>
    <div
      v-for="section in data"
      :key="section.name"
      :ref="section.id"
      class="py-1 rounded avoid-page-break hover:bg-gray-100"
      :class="{ 'cursor-pointer': canClick }"
      @click="$emit('select', { target: $event.target, data: section })"
    >
      <div class="flex items-center justify-between">
        <div class="relative flex-grow truncate ">
          <div
            class="relative text-xs py-2 px-3 z-10 truncate"
            :class="{ 'font-bold': section.id === selected }"
          >
            <span class="font-bold mr-2"
              >{{ Math.round(section.value) }}{{ unit }}</span
            >
            <span class="">{{ section.name }}</span>
          </div>
          <div
            class="absolute rounded inset-y-0 transition-all duration-500"
            :class="{
              [activeClass]: section.id === selected,
              [barClass]: true,
            }"
            :style="{
              width: `max(calc(${(section.value * 100) / max}% - 1px), 2px)`,
            }"
          >
            <svg
              class="
                h-full
                w-full
                text-gray-300
                fill-current
                hidden
                print:block
              "
              :class="{ [activeClass]: section.id === selected }"
            >
              <rect class="h-full w-full" />
            </svg>
          </div>
          <span
            v-if="section.compare !== null && section.compare !== undefined"
            class="
              absolute
              h-full
              w-1
              inset-y-0
              transition-all
              duration-500
              rounded
              print:hidden
            "
            :class="[compareClass]"
            :style="{
              left: (section.compare * 100) / max + '%',
            }"
          ></span>
        </div>
        <div
          class="
            ml-2
            text-sm text-gray-600
            w-12
            text-right
            flex
            items-center
            justify-between
          "
          v-if="section.growth"
        >
          <font-awesome-icon
            :icon="['fas', 'long-arrow-alt-right']"
            class="transform transition-transform duration-300 text-base"
            :class="[section.growth >= 0 ? '-rotate-45' : 'rotate-45']"
          />
          <span>{{ Math.abs(section.growth).toFixed(0) }}%</span>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import { max } from "d3";
export default {
  props: {
    data: {
      type: Array,
      default: () => [
        { name: "Data 1", value: 45, compare: 30, growth: 8 },
        { name: "Data 2", value: 30, compare: 28, growth: -8 },
      ],
    },
    selected: {
      type: String,
      default: null,
    },
    activeClass: {
      type: String,
      default: "",
    },
    barClass: {
      type: String,
      default: "bg-gray-300",
    },
    compareClass: {
      type: String,
      default: "bg-gray-600 bg-opacity-50",
    },
    unit: {
      type: String,
      default: "%",
    },
    canClick: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    max() {
      return max(this.data.map((e) => max([e.value, e.compare]))) || 1;
    },
  },
  methods: {
    showTooltip({ target }, name) {
      this.tooltip.target = target;
      this.tooltip.name = name;
      this.tooltip.show = true;
    },
    hideTooltip() {
      this.tooltip.show = false;
    },
  },
};
</script>
