import * as firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDckAHV5m4Rm7hpD3G83TMKurIsbeOn3Hk",
  authDomain: "indimap-stg.firebaseapp.com",
  projectId: "indimap-stg",
  storageBucket: "indimap-stg.appspot.com",
  messagingSenderId: "782971887154",
  appId: "1:782971887154:web:a00ff213ab82cd149b662b"
};

firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

export { firebase }