<template>
  <div></div>
</template>

<script>
// https://refreshless.com/nouislider/slider-values/
import noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";
export default {
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    value: {
      type: String,
      default: "0-100",
    },
    percent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // Options to configure the slider
    options() {
      // storing min and max values here, otherwise they're undefined in format functions
      const min = this.min;
      const max = this.max;
      const percent = this.percent;
      return {
        start: this.value.split("-"), // array with start positions for handles
        step: percent ? 0.01 : 1,
        tooltips: [true, true],
        connect: [false, true, false], // show a bar between the handles
        range: {
          min: [this.min],
          max: [this.max],
        },
        // Formatting is needed to convert between array and string
        // standard output of slider is array but because this is passed by reference
        // it can create problems when the data updates and range selector needs te re-render
        format: {
          // 'to' the formatted value. Receives a number.
          to(value) {
            if (percent) {
              value *= 100;
            }
            // different rounding for min and max values to have an inclusive range
            // and avoid filtering out players that have values equal to min and max
            if (value === min) {
              return Math.floor(value);
            }
            if (value === max) {
              return Math.ceil(value);
            }
            return value.toFixed(0);
          },
          // 'from' the formatted value.
          // Receives a string, should return a number.
          from(value) {
            return Number(value.replace(",-", ""));
          },
        },
        // cssPrefix: "",
        // cssClasses: {
        //   // Full list of class names to override.
        //   // Does NOT extend the default classes.
        //   // The first class in String gets prefixed, the rest gets added as it is
        // },
      };
    },
  },
  watch: {
    value(newVal) {
      // Set slider values
      // newVal is formatted as a string (min-max) but set() accepts an array
      this.slider.set(newVal.split("-"));
    },
    min() {
      this.update();
    },
    max() {
      this.update();
    },
  },
  methods: {
    // Update slider config when data changes
    update() {
      this.slider.updateOptions(this.options);
    },
    // Build slider with given options
    create() {
      this.slider = noUiSlider.create(this.$el, this.options);
      // Add event listener to send values to parent
      this.slider.on("change", (values) => {
        if (this.percent) {
          values = [+values[0] / 100, +values[1] / 100];
        }
        this.$emit("input", values);
      });
    },
  },
  mounted() {
    this.create();
  },
};
</script>

<style>
.noUi-target {
  /* background-color: #eee !important; */
  @apply bg-gray-200 mb-8;
  border: none !important;
  box-shadow: none !important;
}
.noUi-horizontal {
  height: 10px;
}
.noUi-horizontal .noUi-handle {
  width: 18px;
  height: 18px;
  right: -9px;
  top: -4px;
  border-radius: 50%;
  @apply shadow-inner border-gray-400;
}
.noUi-handle {
  @apply cursor-pointer;
}
.noUi-handle:focus {
  @apply border;
}
.noUi-handle:before,
.noUi-handle:after {
  height: 8px;
  display: none;
}
.noUi-handle:before {
  left: 11px;
}
.noUi-handle:after {
  left: 11px;
}
.noUi-horizontal .noUi-tooltip {
  /* transform: translate(-50%, 0);
  left: 50%; */
  top: 120%;
  bottom: inherit;
  font-size: 0.75rem !important;
  color: #212529;
  padding-top: 0px;
  padding-bottom: 0px;
}
.noUi-connect {
  @apply bg-gray-400;
}
.noUi-tooltip {
  @apply border-gray-400 text-gray-700;
}
</style>
