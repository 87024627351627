const colors = require("tailwindcss/colors");

// color mapping for tailwind color classes
module.exports = {
  primary: "#4C7792",
  gradientMin: colors.sky[100],
  gradientMax: "#4C7792",
  intern: "#CBEC46",
  extern: "#BC24D3",
  grey: "#E0E0E2",
  high: "#009900",
  middle: "#BFE545",
  low: "#FF5050",
};
