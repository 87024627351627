<template>
  <OnClickOutside :do="close">
    <div class="search-select relative" :class="{ 'is-active': isOpen }">
      <button
        ref="button"
        @click="toggle"
        @keydown.enter.prevent="toggle"
        class="w-full truncate px-3 py-2"
        :class="[mainClass]"
        :id="label"
      >
        <!-- <div class="absolute" v-if="showFlags && value">
          <CountryFlag :iso="value" class="h-6 w-6" />
        </div> -->

        <div class="w-full truncate relative">
          <div class="option-name flex-grow truncate pr-4 text-left">
            {{ optionName }}
          </div>

          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center"
          >
            <font-awesome-icon
              :icon="['fas', 'angle-down']"
              class="hide-print text-gray-800"
            />
          </div>
        </div>
      </button>

      <transition name="fade-rotate">
        <div
          v-show="isOpen"
          ref="dropdown"
          class="absolute p-2 rounded-md my-1 bg-white shadow-md z-30 text-base font-normal border"
          :class="{ 'is-open': isOpen }"
          :style="{ width: dropdownWidth }"
        >
          <div class="flex items-center">
            <input
              type="text"
              class="rounded px-4 py-2 border-none mb-2 block w-full bg-gray-100 flex-grow focus:outline-none focus:ring-gray-400 focus:ring-2 "
              v-model="search"
              ref="search"
              @keydown.esc="close"
              @keydown.up="highlightPrev"
              @keydown.down="highlightNext"
              @keydown.enter.prevent="selectHighlighted"
              @keydown.tab.prevent
              :placeholder="placeholder"
              autocomplete="off"
              autocorrect="off"
            />
          </div>
          <ul
            ref="options"
            v-show="filteredOptions.length > 0"
            class="search-select-options country-suggestion-list w-full border-none mb-0 text-gray-700 leading-6 overflow-y-auto overflow-x-hidden relative space-y-1"
          >
            <li
              v-if="value && showAllOptions"
              :key="0"
              class="search-select-option py-2 pl-3 pr-9 flex items-center hover:bg-gray-100"
              @click="select('')"
            >
              <span class="truncate">{{ allOptionsName }}</span>
            </li>
            <li
              class="search-select-option py-1 px-2 flex items-center justify-between hover:bg-gray-100 relative"
              v-for="(option, i) in filteredOptions"
              :key="option.value"
              @click="select(option)"
              :class="{
                'is-active bg-gray-100': i === highlightedIndex,
                'font-semibold': option.id === value,
              }"
            >
              <div class="flex items-center">
                <!-- <CountryFlag :iso="option.iso" class="h-6 w-6 mr-2" /> -->
                <span class=" flex-grow">{{ option.name }}</span>
              </div>
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 text-primary-500"
              >
                <font-awesome-icon
                  v-if="value === option.id"
                  :icon="['fas', 'check']"
                  class=""
                />
              </div>
            </li>
          </ul>
          <div
            v-show="filteredOptions.length === 0"
            class="search-select-empty"
          >
            {{ noResultsText }} "{{ search }}"
          </div>
        </div>
      </transition>
    </div>
  </OnClickOutside>
</template>

<script>
import OnClickOutside from "@/components/OnClickOutside";
export default {
  components: {
    OnClickOutside,
  },
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    options: {
      type: [Array],
      default: () => [],
    },
    filterFunction: {
      type: Function,
      default: (search, options) => {
        return options.filter((option) => {
          const regex = new RegExp(`${search.toLowerCase()}`);
          return option.name.toLowerCase().search(regex) !== -1;
          // option.name.toLowerCase().startsWith(search.toLowerCase())
        });
      },
    },
    placeholder: {
      type: [String],
      default: "",
    },
    showFlags: {
      type: Boolean,
      default: true,
    },
    mainClass: {
      type: [String],
      default: "h3 country-name-title",
    },
    defaultOptionName: {
      type: String,
      default: "All",
    },
    canClear: {
      type: Boolean,
      default: false,
    },
    noResultsText: {
      type: String,
      default: "No results found for ",
    },
    label: {
      type: String,
      default: "",
    },
    dropdownWidth: {
      type: String,
      default: "100%",
    },
    allOptionsName: {
      type: String,
      default: "All",
    },
    showAllOptions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      search: "",
      highlightedIndex: 0,
    };
  },
  computed: {
    // filter displayed options based on search input
    filteredOptions() {
      return this.filterFunction(this.search, this.options);
    },
    // displayed option name
    optionName() {
      return (
        this.options.find((e) => e.id === this.value)?.name ||
        this.defaultOptionName
      );
    },
  },
  methods: {
    // show options
    open() {
      if (this.isOpen) {
        return;
      }
      this.isOpen = true;
      this.$nextTick(() => {
        this.$refs.search.focus();
        this.scrollToHighlighted();
      });
    },
    close() {
      if (!this.isOpen) {
        return;
      }
      this.isOpen = false;
      this.$refs.button.focus();
    },
    toggle() {
      if (this.isOpen) this.close();
      else this.open();
    },
    // send input event when option is selected
    select(option) {
      this.$emit("input", option ? option.id : "");
      this.search = "";
      this.highlightedIndex = 0;
      this.close();
    },
    // select with keyboard
    selectHighlighted() {
      this.select(this.filteredOptions[this.highlightedIndex]);
    },
    scrollToHighlighted() {
      const index =
        this.value && this.showAllOptions
          ? this.highlightedIndex + 1
          : this.highlightedIndex;

      this.$refs.options.children[index].scrollIntoView({
        block: "nearest",
      });
    },
    // highlight options for keyboard navigation
    highlight(index) {
      this.highlightedIndex = index;
      if (this.highlightedIndex < 0) {
        this.highlightedIndex = this.filteredOptions.length - 1;
      }
      if (this.highlightedIndex > this.filteredOptions.length - 1) {
        this.highlightedIndex = 0;
      }
      this.scrollToHighlighted();
    },
    highlightPrev() {
      this.highlight(this.highlightedIndex - 1);
    },
    highlightNext() {
      this.highlight(this.highlightedIndex + 1);
    },
    clear() {
      this.select("");
    },
  },
};
</script>
<style>
.search-select {
  perspective: 1000px;
  z-index: 5;
}
.fade-rotate-enter-active,
.fade-rotate-leave-active {
  transform-origin: 50% -50px;
  transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out;
  transform: rotateX(0deg);
  transform-style: preserve-3d;
  opacity: 1;
}
.fade-rotate-enter,
.fade-rotate-leave-to {
  opacity: 0;
  transform: rotateX(-15deg);
}
.search-select-options {
  max-height: 212px;
}
.search-select-option {
  cursor: pointer;
  border-radius: 0.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.search-select-empty {
  padding: 0.5rem 0.75rem;
  color: #b8c2cc;
}
.search-select.is-active {
  z-index: 10000;
}
.option-name {
  white-space: nowrap;
  max-width: calc(100vw - 95px);
  overflow-x: hidden;
  overflow-y: hidden;
}
.option-name.no-flag {
  max-width: calc(100vw);
}
.country-card .option-name {
  padding-bottom: 0;
}
</style>
