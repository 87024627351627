<template>
  <div class="container">
    <div class="max-w-4xl mx-auto">
      <h2 class="ot-title-2 ">
        Características
      </h2>

      <!-- description -->
      <div id="ter-description" class="mb-16 md:mb-20 " v-html="terText"></div>

      <h2 class="ot-title-2 ">
        Proyectos activos en el territorio
      </h2>

      <!-- operadores filter -->
      <div class="">
        <SearchSelect
          v-model="selectedOrg"
          :options="organizations"
          :default-option-name="`Selecciona un operador`"
          :all-options-name="`Todos los operadores`"
          :show-all-options="true"
          main-class=""
          class="rounded border border-gray-800 w-72 text-gray-800 bg-white mb-6"
        />
      </div>

      <!-- projects table -->
      <div class="max-h-[600px] overflow-auto ">
        <table v-if="terProjects.length" class="text-left w-full">
          <thead>
            <tr class="border-b">
              <th class="ot-title-4 pr-3">
                Operadores
              </th>
              <th class="ot-title-4">Proyectos</th>
            </tr>
          </thead>
          <tbody class="divide-y">
            <tr v-for="(project, i) in projectsList" :key="i">
              <td class="py-2 pr-3">{{ project.org }}</td>
              <td class="py-2">{{ project.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
const md = require("markdown-it")();

import SearchSelect from "@/components/SearchSelect";

import api from "@/services/data";
// import colors from "@/utils/colors";
import * as IDS from "@/utils/ids";
import { groupBy } from "@/utils/helpers";

export default {
  name: "Home",
  components: {
    SearchSelect,
  },
  data() {
    return {
      actors: [],
      territories: [],
      projects: [],
      texts: [],
      publicPath: process.env.BASE_URL,
      selectedOrg: null,
    };
  },
  computed: {
    // territory param
    terId() {
      return this.$route.params.terId || null;
    },
    // actors filtered by selected territory
    terActors() {
      return this.terId
        ? this.actors.filter((e) => this.terId === e[IDS.AC.TER])
        : this.actors;
    },
    // projects filtered by selected territory
    terProjects() {
      return this.terId
        ? this.projects.filter((e) => this.terId === e[IDS.PROJECT.TER])
        : this.projects;
    },
    // filtered by operador
    filteredProjects() {
      return this.selectedOrg
        ? this.terProjects.filter(
            (e) => e[IDS.PROJECT.ORG] === this.selectedOrg
          )
        : this.terProjects;
    },
    // list of projects
    projectsList() {
      return (
        this.filteredProjects
          .map((e) => {
            return {
              name: e[IDS.PROJECT.NAME],
              org: e[IDS.PROJECT.ORG],
            };
          })
          // alphabetical order org
          .sort(function(a, b) {
            if (a.org > b.org) {
              return 1;
            }
            if (b.org > a.org) {
              return -1;
            }
            return 0;
          })
      );
    },
    // list of organizations
    organizations() {
      return [...new Set(this.terProjects.map((e) => e[IDS.PROJECT.ORG]))]
        .sort()
        .map((e) => {
          return {
            id: e,
            name: e,
            value: e,
          };
        });
    },
    actorsByFunction() {
      return groupBy(this.terActors, IDS.AC.FUNCTION);
    },
    terText() {
      // find territory text
      const obj = this.texts.find((e) => e[IDS.DESC.TER] === this.terId);
      // store markdown
      let source = obj ? obj[IDS.DESC.TEXT] : "";
      // replace public paths for image urls
      source = source.replace("${publicPath}", this.publicPath);

      // convert md to html
      const result = md.render(source);

      return result;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.error = false;

        this.actors = await api.getActors();
        this.territories = await api.getTerritories();
        this.projects = await api.getProjects();
        this.texts = await api.getTexts();
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style>
#ter-description p {
  @apply my-4;
}

#ter-description ul {
  @apply list-disc list-inside;
}
</style>
