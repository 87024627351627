// actors data
export const AC = {
  TER: "id territorio",
  TER_NAME: "territorio",
  ID: "id actor",
  NAME: "actor clave",
  TYPE: "Subcategoria 1",
  FUNCTION: "Subcategoria 2",
  LEG: "Legitimidad",
  POD: "Poder",
  INF: "Influencia",
  INT: "Interés",
  CON: "Conocimiento",
  PRE: "Prestigio",
  COO: "Cooperación requerida",
};

// territories dict
export const TER = {
  ID: "id territorio",
  NAME: "territorio",
};

// projects data
export const PROJECT = {
  TER: "id territorio",
  NAME: "nombre proyecto",
  ORG: "nombre institucion",
};

// indicators dict
export const IND = {
  MAIN: "Indicador",
  SUB: "Subindicador",
};

// parties data
export const PAR = {
  TER: "id territorio",
  NAME: "nombre partido",
  COUNT: "numero adherentes",
  PERC: "percent per territory",
};

// positions data
export const POS = {
  TER: "id territorio",
  DIP: "Diputado (Circuito electoral)",
  DIP_PAR: "Partido Político Diputado",
  ALC: "Alcalde",
  ALC_PAR: "Partido Político Alcalde",
  REP: "Representante Corregimiento",
  REP_PAR: "Partido Político Representante",
};

// territory descriptions
export const DESC = {
  TER: "id territorio",
  TEXT: "text",
};
