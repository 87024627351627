// Group array by a property
export function groupBy(arr, prop) {
  // return object with groups as keys
  return arr.reduce((obj, value) => {
    // store group name
    const group = value[prop];
    // initialize group
    if (!obj[group]) obj[group] = [];
    // add element to group
    obj[group].push(value);

    return obj;
  }, {});
}
