import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLongArrowAltRight,
  faCheck,
  faTimes,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faHome,
  faMars,
  faVenus,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faLongArrowAltRight,
  faCheck,
  faTimes,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faHome,
  faMars,
  faVenus,
  faCaretDown
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
