<template>
  <div ref="container" class="column-container isolate">
    <svg ref="svg" class="overflow-visible text-gray-500 fill-current" />
    <VTooltip :show="tooltip.show" :target="tooltip.target">
      <slot :tooltipData="tooltip.data">
        <div class="font-bold text-base">{{ tooltip.name }}</div>
        <div>{{ tooltip.value }}</div>
      </slot>
    </VTooltip>
  </div>
</template>

<script>
import * as d3 from "d3";
import VTooltip from "@/components/VTooltip";

export default {
  name: "ColumnChart",
  components: {
    VTooltip,
  },
  props: {
    data: {
      type: Array,
      default: () => [
        { name: "Val 1", value: 50, color: "#eeeeee" },
        { name: "Val 2", value: 100, color: "#cccccc" },
      ],
    },
    max: {
      type: Number,
      default: null,
    },
    color: {
      type: String,
      default: "#ccc",
    },
    duration: {
      type: Number,
      default: 300,
    },
    average: {
      type: Number,
      default: null,
    },
    averageText: {
      type: String,
      default: "media total",
    },
    ease: {
      type: String,
      default: "easeCubic",
    },
    margin: {
      type: Object,
      default: () => ({ top: 0, right: 15, bottom: 0, left: 0 }),
    },
    showXAxis: {
      type: Boolean,
      default: true,
    },
    showYAxis: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: [String, Number],
      default: null,
    },
    strokeColor: {
      type: String,
      default: "#000000",
    },
    unit: {
      type: String,
      default: "%",
    },
    selectedName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      width: 500,
      height: 200,
      hoverIndex: null,
      tooltip: {
        show: false,
        name: "",
        value: null,
        data: {},
      },
    };
  },
  computed: {
    computedMax() {
      if (this.max) return this.max;
      return d3.max(this.data, (d) => d.value);
    },
    x() {
      return d3
        .scaleBand()
        .domain(this.data.map((e) => e.name))
        .range([this.margin.left, this.width - this.margin.right])
        .padding(0.1);
    },
    y() {
      return d3
        .scaleLinear()
        .domain([0, this.computedMax])
        .nice()
        .range([this.height - this.margin.bottom, this.margin.top]);
    },
  },
  watch: {
    data() {
      this.draw();
    },
    selected() {
      this.highlight();
    },
  },
  mounted() {
    this.setSize();
    this.createChart();
    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      this.resizeObserver.observe(this.$refs.container);
    } else {
      // for IE
      window.addEventListener("resize", this.resize);
    }
  },
  beforeUnmount() {
    if (window.ResizeObserver) {
      this.resizeObserver.unobserve(this.$refs.container);
    } else {
      // for IE
      window.removeEventListener("resize", this.resize);
    }
  },
  methods: {
    setSize() {
      const container = this.$refs.container;
      if (container) {
        this.width = container.clientWidth;
        this.height = container.clientHeight;
      }
    },
    resize() {
      const currentWidth = this.width;
      const currentHeight = this.height;
      this.setSize();
      if (currentWidth !== this.width || currentHeight !== this.height) {
        this.updateChart();
      }
    },
    draw(delay = this.duration, duration = this.duration) {
      const selection = this.rects
        .selectAll("rect")
        .data(this.data, (d) => d.name);
      // calculate delays
      const updating = selection;
      // const exiting = selection.exit();
      const updateDelay1 = 0; //exiting.size() ? delay : 0;
      const updateDelay = updateDelay1 + updating.size() ? updateDelay1 : 0;
      const enterDelay = updateDelay + (updating.size() ? delay * 2 : delay);
      const enterDelayAxis = 0; // updateDelay + (updating.size() ? delay : 0);
      this.drawAxis(enterDelayAxis, duration);
      selection.join(
        (enter) =>
          enter
            .append("rect")
            .attr("fill", (d) => d.color)
            .attr("id", (d) => `column-${d.id}`)
            .attr("x", (d) => this.x(d.name))
            // .attr("y", () => this.y(0))
            // .attr("height", 0)
            .attr("y", (d) => (d.value ? this.y(d.value) : this.y(0.5)))
            .attr("height", (d) =>
              d.value ? this.y(0) - this.y(d.value) : this.y(0) - this.y(0.5)
            )
            .attr("width", this.x.bandwidth())
            .attr("cursor", "pointer")
            .on("mouseenter", (event, data) => {
              this.$emit("hover", { event, data });
              this.showTooltip(event, data);
              d3.select(`#column-${data.id}`).attr("stroke", "black");
            })
            .on("mouseleave", (event, data) => {
              this.$emit("hover", null);
              this.hideTooltip();
              d3.select(`#column-${data.id}`).attr("stroke", "none");
            })
            .on("click", (event, data) => this.$emit("click", { event, data }))
            .call((enter) =>
              enter
                .transition()
                .duration(duration)
                .ease(d3[this.ease])
                .delay(enterDelay)
                .attr("y", (d) => (d.value ? this.y(d.value) : this.y(0.5)))
                .attr("height", (d) =>
                  d.value
                    ? this.y(0) - this.y(d.value)
                    : this.y(0) - this.y(0.5)
                )
            ),
        (update) =>
          update.call((update) =>
            update
              .transition()
              .duration(duration)
              .ease(d3[this.ease])
              .delay(updateDelay)
              // first animate the height of the bar
              .attr("y", (d) => (d.value ? this.y(d.value) : this.y(0.5)))
              .attr("height", (d) =>
                d.value ? this.y(0) - this.y(d.value) : this.y(0) - this.y(0.5)
              )
              // and the color
              .attr("fill", (d) => d.color)
              // and then move into the new position
              .transition()
              .attr("x", (d) => this.x(d.name))
              .attr("width", this.x.bandwidth())
          ),
        (exit) =>
          exit
            .transition()
            .duration(duration)
            .ease(d3[this.ease])
            .attr("y", () => this.y(0))
            .attr("height", 0)
            .remove()
      );
      // average line
      if (this.average !== null) {
        this.avg
          .attr("display", this.average !== null ? null : "none")
          .call((g) =>
            g
              .select("line")
              .attr("x1", this.margin.left)
              .attr("x2", this.width)
          )
          .transition()
          .duration(duration)
          .delay(delay)
          .attr("transform", `translate(0,${this.y(this.average)})`);
      }

      if (this.selectedName) {
        if (this.arrow) this.arrow.remove();

        this.arrow = this.xAxis
          .append("g")
          // .attr("transform", "translate(-8, 0)")
          .attr(
            "transform",
            `translate(${this.x(this.selectedName) -
              9.5 +
              this.x.bandwidth() / 2}, -1)`
          )
          .append("path")
          .attr("fill", "#4C7792")
          .attr(
            "d",
            "M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
          )
          .attr("fill-rule", "evenodd")
          .attr("clip-rule", "evenodd");
      }
    },
    drawAxis(delay, duration) {
      if (this.showXAxis) {
        // AXIS
        this.xAxis
          .attr(
            "transform",
            "translate(-0.5," + (this.height - this.margin.bottom) + ")"
          )
          .transition()
          .duration(duration)
          .ease(d3[this.ease])
          .delay(delay)
          .call(
            d3.axisBottom(this.x) //.ticks(this.numTicks)
            // .tickFormat((d) => this.data[0][d]?.label || d)
          );
        //.call((g) => g.select(".domain").remove());
      }
      if (this.showYAxis) {
        this.yAxis
          .attr("transform", `translate(${this.width - this.margin.right},0)`)
          // .transition()
          // .duration(duration)
          // .ease(d3[this.ease])
          // .delay(delay)
          .call(
            d3
              .axisRight(this.y)
              .ticks(3)
              .tickFormat((d) => `${d}${this.unit}`)
              .tickSize(-(this.width - this.margin.left - this.margin.right))
          );
      }
    },
    highlight() {
      if (this.selected === null) {
        this.rects.selectAll("rect").attr("stroke-opacity", 0);
      } else {
        this.rects
          .select(`#column-${this.selected}`)
          .attr("stroke-width", 1)
          .attr("stroke-opacity", 1)
          .attr("stroke", this.strokeColor);
      }
    },
    updateChart() {
      this.svg.attr("viewBox", [0, 0, this.width, this.height]);
      this.draw(0, 0);
    },
    createChart() {
      if (!this.svg) {
        this.svg = d3
          .select(this.$refs.svg)
          .attr("viewBox", [0, 0, this.width, this.height]);
        this.yAxis = this.svg
          .append("g")
          .attr("class", "y-axis")
          .attr("transform", `translate(${this.width - this.margin.right},0)`);
        this.rects = this.svg.append("g");
        this.xAxis = this.svg
          .append("g")
          .attr("class", "x-axis")
          .attr(
            "transform",
            "translate(-0.5," + (this.height - this.margin.bottom) + ")"
          );
        this.avg = this.svg
          .append("g")
          .attr("class", "average")
          .attr("display", this.average !== null ? null : "none");
        this.avg.append("line").attr("stroke", "black");
        this.avg
          .append("text")
          .attr("class", "text-xs")
          .attr("fill", "black")
          .attr("x", this.margin.left)
          .attr("dy", "-4px")
          .text(this.averageText);
        //first paint with no delay
        this.draw(0);
      }
    },
    showTooltip(e, data) {
      this.tooltip.name = data.name;
      this.tooltip.target = e.target;
      this.tooltip.value = data.value;
      this.tooltip.data = data;
      this.tooltip.show = true;
    },
    hideTooltip() {
      this.tooltip.show = false;
    },
  },
};
</script>

<style>
.column-container .y-axis .tick line {
  stroke-opacity: 0.2;
  /* stroke-dasharray: 2, 2; */
}
.column-container .y-axis .domain {
  display: none;
}
</style>
