<template>
  <div class="flex items-end" :style="{ height: `${maxHeight}px` }">
    <div
      v-for="(bin, i) in bins"
      :key="bin.x0"
      class="flex-grow"
      :style="{
        height: `${bin.length * binHeightScale}px`,
        background: colorScale ? colorScale(binValues[i]) : color,
      }"
    ></div>
  </div>
</template>

<script>
import * as d3 from "d3";
export default {
  name: "Histogram",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    metric: {
      type: String,
      default: "",
    },
    colorScale: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: "#e5e7eb",
    },
  },
  data() {
    return {
      nthresholds: 15,
      maxHeight: 35,
    };
  },
  computed: {
    values() {
      return this.data.map((e) => +e[this.metric]);
    },
    bins() {
      return d3.bin().thresholds(this.nthresholds)(this.values);
    },
    // calculate height of bin with 1 data point
    binHeightScale() {
      let maxBinSize = Math.max(...this.bins.map((bin) => bin.length));
      return this.maxHeight / maxBinSize;
    },
    // get average  value of each bin to use in color scale
    binValues() {
      return this.bins.map((bin) => {
        // calculate average value of bin (each bin is an array of values)
        return bin.length
          ? bin.reduce((acc, cur) => acc + cur, 0) / bin.length
          : null;
      });
    },
  },
};
</script>
