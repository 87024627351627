<template>
  <div ref="container" class="legend-container">
    <svg ref="svg" class="overflow-visible fill-current" />
  </div>
</template>

<script>
import * as d3 from "d3";
// Vue implementation of:
// https://observablehq.com/@d3/color-legend
export default {
  name: "Legend",
  props: {
    color: {
      type: Function,
      default: d3.scaleSequential([0, 100], d3.interpolateViridis),
    },
    margin: {
      type: Object,
      default: () => ({ top: 0, right: 0, bottom: 16, left: 0 }),
    },
    ticks: {
      type: Number,
      default: undefined,
    },
    tickSize: {
      type: Number,
      default: 6,
    },
    tickValues: {
      type: Number,
      default: undefined,
    },
    tickFormat: {
      type: [Number, String, Function],
      default: undefined,
    },
    duration: {
      type: Number,
      default: 300,
    },
    ease: {
      type: String,
      default: "easeCubic",
    },
  },
  data() {
    return {
      width: 320,
      height: 26 + this.margin.top,
    };
  },
  computed: {
    useTicks() {
      return this.ticks || this.width / 64;
    },
  },
  watch: {
    color() {
      this.draw();
    },
  },
  mounted() {
    this.setSize();
    this.createChart();
    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      this.resizeObserver.observe(this.$refs.container);
    } else {
      // for IE
      window.addEventListener("resize", this.resize);
    }
  },
  beforeDestroy() {
    if (window.ResizeObserver) {
      this.resizeObserver.unobserve(this.$refs.container);
    } else {
      // for IE
      window.removeEventListener("resize", this.resize);
    }
  },
  methods: {
    setSize() {
      const container = this.$refs.container;
      if (container) {
        this.width = container.clientWidth;
        // this.height = container.clientHeight;
      }
    },
    resize() {
      const currentWidth = this.width;
      const currentHeight = this.height;
      this.setSize();
      if (currentWidth !== this.width || currentHeight !== this.height) {
        if (this.svg) this.draw();
      }
    },
    draw() {
      const that = this;
      let tickAdjust = (g) =>
        g
          .selectAll(".tick line")
          .attr("y1", this.margin.top + this.margin.bottom - this.height);
      let x;
      let useTickValues;
      let useTickFormat;
      // Continuous
      if (this.color.interpolate) {
        const n = Math.min(
          this.color.domain().length,
          this.color.range().length
        );
        x = this.color
          .copy()
          .rangeRound(
            d3.quantize(
              d3.interpolate(this.margin.left, this.width - this.margin.right),
              n
            )
          );
        this.image
          .attr("x", this.margin.left)
          .attr("y", this.margin.top)
          .attr("width", this.width - this.margin.left - this.margin.right)
          .attr("height", this.height - this.margin.top - this.margin.bottom)
          .attr("preserveAspectRatio", "none")
          .attr(
            "xlink:href",
            this.ramp(
              this.color.copy().domain(d3.quantize(d3.interpolate(0, 1), n))
            ).toDataURL()
          );
        useTickValues = this.tickValues;
        useTickFormat = this.tickFormat;
      }
      // Sequential
      else if (this.color.interpolator) {
        x = Object.assign(
          this.color
            .copy()
            .interpolator(
              d3.interpolateRound(
                this.margin.left,
                this.width - this.margin.right
              )
            ),
          {
            range() {
              return [that.margin.left, that.width - that.margin.right];
            },
          }
        );
        this.image
          .attr("x", this.margin.left)
          .attr("y", this.margin.top)
          .attr("width", this.width - this.margin.left - this.margin.right)
          .attr("height", this.height - this.margin.top - this.margin.bottom)
          .attr("preserveAspectRatio", "none")
          .attr("xlink:href", this.ramp(this.color.interpolator()).toDataURL());
        // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
        if (!x.ticks) {
          if (this.tickValues === undefined) {
            const n = Math.round(this.useTicks + 1);
            useTickValues = d3
              .range(n)
              .map((i) => d3.quantile(this.color.domain(), i / (n - 1)));
          }
          // I don't know what this is doing
          // if (typeof this.tickFormat !== "function") {
          //   useTickFormat = d3.format(
          //     this.tickFormat === undefined ? ",f" : this.tickFormat
          //   );
          // }
        }
        useTickFormat = this.tickFormat;
      }
      // axis
      this.axisContainer
        .attr("transform", `translate(0,${this.height - this.margin.bottom})`)
        .transition()
        .duration(this.duration)
        .ease(d3[this.ease])
        .call(
          d3
            .axisBottom(x)
            .ticks(
              this.useTicks,
              typeof useTickFormat === "string" ? useTickFormat : undefined
            )
            .tickFormat(
              // d => `${d}%`
              typeof useTickFormat === "function" ? useTickFormat : undefined
            )
            .tickSize(this.tickSize)
            .tickValues(useTickValues)
        )
        .call(tickAdjust);
      // .call((g) => g.select(".domain").remove());
      // .call((g) =>
      //   g
      //     .append("text")
      //     .attr("x", this.margin.left)
      //     .attr("y", this.margin.top + this.margin.bottom - this.height - 6)
      //     .attr("fill", "currentColor")
      //     .attr("text-anchor", "start")
      //     .attr("font-weight", "bold")
      //     .attr("class", "title")
      //     .text(this.title)
      // );
    },
    ramp(color, n = 256) {
      // const canvas = DOM.canvas(n, 1);
      const canvas = document.createElement("canvas");
      canvas.width = n;
      canvas.height = 1;
      const context = canvas.getContext("2d");
      for (let i = 0; i < n; ++i) {
        context.fillStyle = color(i / (n - 1));
        context.fillRect(i, 0, 1, 1);
      }
      return canvas;
    },
    createChart() {
      if (!this.svg) {
        this.svg = d3
          .select(this.$refs.svg)
          .attr("width", this.width)
          .attr("height", this.height)
          .attr("viewBox", [0, 0, this.width, this.height])
          .style("overflow", "visible")
          .style("display", "block");
        this.image = this.svg.append("image");
        this.axisContainer = this.svg
          .append("g")
          .attr("class", "ot-legend-axis");
      }
      this.draw();
    },
  },
};
</script>
<style>
.ot-legend-axis .domain {
  display: none;
}
</style>
