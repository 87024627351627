<template>
  <div class="">
    <!-- mobile nav -->
    <div class="flex md:hidden justify-between container">
      <router-link
        class="md:hidden flex items-center gap-x-2 text-primary text-xs md:text-base"
        :class="{ disabled: !prevActorId }"
        :to="{ path: `/${terId}/actores/${prevActorId}` }"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Anterior</span></router-link
      >

      <router-link
        class="md:hidden flex items-center gap-x-2 text-primary text-xs md:text-base"
        :class="{ disabled: !nextActorId }"
        :to="{ path: `/${terId}/actores/${nextActorId}` }"
        ><span>Siguiente</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
          <path
            fill-rule="evenodd"
            d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          /></svg
      ></router-link>
    </div>

    <!-- nav -->
    <div class="md:sticky top-[100px] z-20 bg-white">
      <div class="container pt-3">
        <div class="flex justify-center items-center gap-x-12 mb-1">
          <router-link
            class="hidden md:flex items-center gap-x-2 text-primary text-xs md:text-base"
            :class="{ disabled: !prevActorId }"
            :to="{ path: `/${terId}/actores/${prevActorId}` }"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Anterior</span></router-link
          >
          <h2 class="ot-title-2 mb-0 text-sm md:text-base">
            {{ actorData[IDS.AC.NAME] }}
          </h2>
          <router-link
            class="hidden md:flex items-center gap-x-2 text-primary text-xs md:text-base"
            :class="{ disabled: !nextActorId }"
            :to="{ path: `/${terId}/actores/${nextActorId}` }"
            ><span>Siguiente</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
              <path
                fill-rule="evenodd"
                d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              /></svg
          ></router-link>
        </div>

        <div
          class="md:flex justify-center gap-x-10 p-2 mb-4 xl:gap-x-20 text-sm md:text-base"
        >
          <div>Tipo: {{ actorData[IDS.AC.TYPE] }}</div>
          <div>Función: {{ actorData[IDS.AC.FUNCTION] }}</div>
        </div>
        <div
          class="md:flex justify-center pt-2"
          :class="{ 'gap-x-24': isParty }"
        >
          <RadarChart
            :data="radarChartData"
            :levels="[10, 20, 30]"
            :shape-color="radarColor"
            class="w-52 md:w-80 md:h-80 mx-auto md:mx-0"
          />
          <div v-if="isParty" class="mt-6 md:mt-0 md:pb-3 md:pt-2">
            <div class="ot-title-4 mb-1">Número de adherentes por partido</div>
            <div class="text-sm text-primary mb-1">
              Haz click para ver la distribución territorial
            </div>
            <div class="max-h-[200px] overflow-auto">
              <HorizontalBarChart
                :data="barChartParties"
                :can-click="true"
                @select="openModal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- table positions -->
    <div
      v-if="isParty"
      class="container my-10 flex flex-wrap md:flex-nowrap gap-y-6 gap-x-10 items-start"
      :class="{
        'justify-between':
          representantes.length && alcaldes.length && diputados.length,
      }"
    >
      <div class="max-h-[400px] overflow-auto">
        <table v-if="representantes.length" class="text-left">
          <thead>
            <tr class="border-b">
              <th class="ot-title-4 pr-3">
                representante corregimiento
              </th>
              <th class="ot-title-4 whitespace-nowrap">partido político</th>
            </tr>
          </thead>
          <tbody class="divide-y">
            <tr v-for="rep in representantes" :key="rep.name">
              <td class="pr-3 py-2">{{ rep.name }}</td>
              <td class="py-2">{{ rep.party }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="max-h-[400px] overflow-auto">
        <table v-if="alcaldes.length" class="text-left">
          <thead>
            <tr class="border-b">
              <th class="ot-title-4 pr-3">alcalde</th>
              <th class="ot-title-4 whitespace-nowrap">partido político</th>
            </tr>
          </thead>
          <tbody class="divide-y">
            <tr v-for="alcalde in alcaldes" :key="alcalde.name">
              <td class="pr-3 py-2">{{ alcalde.name }}</td>
              <td class="py-2">{{ alcalde.party }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="max-h-[400px] overflow-auto">
        <table v-if="diputados.length" class="text-left">
          <thead>
            <tr class="border-b">
              <th class="ot-title-4 pr-3">diputado</th>
              <th class="ot-title-4 whitespace-nowrap">partido político</th>
            </tr>
          </thead>
          <tbody class="divide-y">
            <tr v-for="dip in diputados" :key="dip.name">
              <td class="pr-3 py-2">{{ dip.name }}</td>
              <td class="py-2">{{ dip.party }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- main indicators -->
    <div class="container py-6 md:py-10">
      <!-- container -->
      <div
        v-for="indicator in mainIndicatorsData"
        :key="indicator.name"
        class="py-10"
      >
        <!-- title -->
        <h3 class="ot-title-3 border-b border-gray-800 pb-1 mb-6">
          {{ indicator.name }}
        </h3>

        <!-- content -->
        <div class="flex flex-col md:flex-row space-y-6 md:space-y-0">
          <!-- color scale and chart -->
          <div class="md:w-1/2 md:pr-10 flex flex-col justify-between">
            <!-- color scale -->
            <div class="ot-title-4 mb-6">Indicador sintetizado</div>
            <div class="relative mb-6">
              <div
                class="absolute"
                :style="{
                  right: `calc(${(indicator.value / 30) * 100}% - 8px)`,
                  top: '-25px',
                }"
              >
                <div class="flex flex-col items-center -space-y-1 -mb-1 w-4">
                  <div class="text-xs font-bold">
                    {{ indicator.value }}
                  </div>
                  <font-awesome-icon :icon="['fas', 'caret-down']" />
                </div>
              </div>
              <div class="relative">
                <GradientLegend
                  class="h-3 transform rotate-180"
                  :color-scale="divergingScale"
                />
                <div
                  class="absolute h-4 bg-gray-900"
                  :style="{
                    width: '2px',
                    top: '-4px',
                    left: 'calc(50% - 1px)',
                  }"
                ></div>
              </div>
              <div class="flex justify-between text-xs">
                <div>30 - Alto</div>
                <div>15 - Medio</div>
                <div>0 - Nulo</div>
              </div>
            </div>

            <!-- subindicators -->
            <div class="ot-title-4 mb-2">
              Sub-indicadores
            </div>
            <div v-if="indicator.subIndicators">
              <Subindicators>
                <div class="divide-y text-sm px-1">
                  <div
                    v-for="item in indicator.subIndicators"
                    :key="item.name"
                    class="flex justify-between space-x-2 py-3 rounded-sm"
                  >
                    <div>{{ item.name }}</div>
                    <svg
                      v-if="item.value === 'Sí'"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <svg
                      v-else-if="item.value === 'No'"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <div v-else class="whitespace-nowrap font-bold">
                      {{ item.value }}
                    </div>
                  </div>
                </div>
              </Subindicators>
            </div>
            <div v-else>Para este indicador no hay sub-indicadores</div>
          </div>

          <!-- ranking -->
          <div class="md:w-1/2 md:pl-10">
            <div class="ot-title-4 mb-3">
              Ranking respecto a los otros actores
            </div>
            <div>
              <ColumnChart
                :data="indicator.data"
                :selected-name="actorData[IDS.AC.NAME]"
                :show-x-axis="false"
                :max="30"
                unit=""
                @click="routeActor($event.data.actorId)"
                v-slot="{ tooltipData }"
                class="h-36"
              >
                <div class="text-base font-bold">
                  {{ tooltipData.name }}
                </div>
                <div class="text-gray-500 text-sm">
                  {{ tooltipData.indicator }}
                </div>
                <div class="font-semibold text-lg">
                  {{ tooltipData.value }}
                </div>
              </ColumnChart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal opens when bar is clicked  -->
    <TwModal :is-open="showModal" @close="showModal = false">
      <span slot="header">{{ selectedParty }}</span>
      <div slot="subtitle" class="text-sm text-primary mt-2">
        % de adherentes en los diferentes territorios
      </div>
      <div slot="body">
        <HorizontalBarChart :data="barChartSelectedParty" class="max-w-sm" />
      </div>
    </TwModal>
  </div>
</template>

<script>
// import LegendColor from "@/components/LegendColor";
import RadarChart from "@/components/RadarChart";
import ColumnChart from "@/components/ColumnChart";
import GradientLegend from "@/components/GradientLegend";
import Subindicators from "@/components/Subindicators";
import HorizontalBarChart from "@/components/HorizontalBarChart";
import TwModal from "@/components/TwModal";

import * as d3 from "d3";

import api from "@/services/data";
import colors from "@/utils/colors";
import * as IDS from "@/utils/ids";
import { categories } from "@/utils/mapping";
// import { groupBy } from "@/utils/helpers";

export default {
  name: "Home",
  components: {
    // LegendColor,
    RadarChart,
    ColumnChart,
    GradientLegend,
    Subindicators,
    HorizontalBarChart,
    TwModal,
  },
  data() {
    return {
      actors: [],
      territories: [],
      indicators: [],
      mainIndicators: [],
      parties: [],
      positions: [],
      grayColor: "#E5E7EB",
      radarColor: "#D6E249",
      divergingScale: [colors.low, colors.middle, colors.high],
      IDS,
      showModal: false,
      selectedParty: "",
    };
  },
  computed: {
    // territory param
    terId() {
      return this.$route.params.terId || null;
    },
    // actors filtered by selected territory
    terActors() {
      return this.actors.filter((e) => this.terId === e[IDS.AC.TER]);
    },
    // check if actor is political party
    isParty() {
      return this.actorData[IDS.AC.NAME]
        ? this.actorData[IDS.AC.NAME].toUpperCase() === "PARTIDOS POLÍTICOS"
        : false;
    },
    // political parties filtered by selected territory
    terParties() {
      return this.parties.filter((e) => this.terId === e[IDS.PAR.TER]);
    },
    // actor param
    actorId() {
      return this.$route.params.actorId || this.terActors[0][IDS.AC.ID];
    },
    currentActorIndex() {
      return this.terActors.findIndex((e) => e[IDS.AC.ID] === this.actorId);
    },
    // next and previous actor id for nav
    nextActorId() {
      if (
        this.currentActorIndex === this.terActors.length - 1 ||
        !this.terActors.length
      )
        return null;
      else return this.terActors[this.currentActorIndex + 1][IDS.AC.ID];
    },
    prevActorId() {
      if (!this.currentActorIndex || !this.terActors.length) return null;
      else return this.terActors[this.currentActorIndex - 1][IDS.AC.ID];
    },
    // data for parties bar chart
    barChartParties() {
      const total = this.terParties.reduce(
        (acc, cur) => acc + cur[IDS.PAR.COUNT],
        0
      );

      return this.terParties
        .map((e) => {
          return {
            name: e[IDS.PAR.NAME],
            value: (e[IDS.PAR.COUNT] / total) * 100,
            count: e[IDS.PAR.COUNT],
          };
        })
        .sort((a, b) => b.value - a.value);
    },
    // data for bar chart selected party
    barChartSelectedParty() {
      const values = this.parties.filter(
        (party) => party[IDS.PAR.NAME] === this.selectedParty
      );

      return values
        .map((e) => {
          return {
            name: this.territories.find(
              (ter) => ter[IDS.TER.ID] === e[IDS.PAR.TER]
            )[IDS.TER.NAME],
            value: e[IDS.PAR.PERC] * 100,
          };
        })
        .sort((a, b) => b.value - a.value);
    },
    // values for selected actor
    actorData() {
      return this.terActors.find((e) => e[IDS.AC.ID] === this.actorId) || {};
    },
    tableColorScale() {
      return d3.scaleDiverging(d3.interpolateRdBu).domain([0, 15, 30]);
    },
    radarChartData() {
      return this.mainIndicators.map((e) => {
        const id = e.slice(0, 3).toUpperCase();
        return {
          id,
          name: e,
          value: this.actorData[IDS.AC[id]],
        };
      });
    },
    mainIndicatorsData() {
      return this.mainIndicators.map((indicator) => {
        const id = indicator.slice(0, 3).toUpperCase();

        const subIndicators = this.indicators.filter(
          (e) => e[IDS.IND.MAIN] === indicator
        );

        return {
          id,
          name: indicator,
          value: this.actorData[IDS.AC[id]],
          subIndicators: subIndicators.map((e) => {
            const value = this.actorData[e[IDS.IND.SUB]];

            // check if indicator is categorical
            const dict = categories[e[IDS.IND.SUB]];

            return {
              name: e[IDS.IND.SUB],
              value: dict ? dict[value] : value,
            };
          }),
          data: this.terActors
            .map((actor, i) => {
              return {
                id: `${indicator}-${i}`,
                actorId: actor[IDS.AC.ID],
                indicator,
                name: actor[IDS.AC.NAME],
                value: actor[IDS.AC[id]],
                color:
                  actor[IDS.AC.ID] === this.actorId ? "#4C7792" : "#D1D5DB",
              };
            })
            .sort((a, b) => b.value - a.value),
        };
      });
    },
    terPositions() {
      return this.positions.filter((e) => e[IDS.POS.TER] === this.terId);
    },
    // representante corregimiento
    representantes() {
      return this.terPositions
        .filter((e) => e[IDS.POS.REP])
        .map((e) => ({ name: e[IDS.POS.REP], party: e[IDS.POS.REP_PAR] }));
    },
    // alcaldes
    alcaldes() {
      return this.terPositions
        .filter((e) => e[IDS.POS.ALC])
        .map((e) => ({ name: e[IDS.POS.ALC], party: e[IDS.POS.ALC_PAR] }));
    },
    // diputados
    diputados() {
      return this.terPositions
        .filter((e) => e[IDS.POS.DIP])
        .map((e) => ({ name: e[IDS.POS.DIP], party: e[IDS.POS.DIP_PAR] }));
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.error = false;
        this.actors = await api.getActors();
        this.territories = await api.getTerritories();
        this.indicators = await api.getIndicators();
        this.parties = await api.getParties();
        this.positions = await api.getPositions();

        this.mainIndicators = [
          ...new Set(this.indicators.map((e) => e[IDS.IND.MAIN])),
        ];
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    routeActor(actorId) {
      this.$router.push({
        path: `/${this.terId}/actores/${actorId}`,
      });
    },
    openModal(e) {
      this.selectedParty = e.data.name;

      this.showModal = true;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style>
.disabled {
  @apply pointer-events-none opacity-50 cursor-not-allowed;
}
</style>
