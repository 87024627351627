<template>
  <div>
    <ButtonPlus
      @click="status = !status"
      :status="status"
      button-class="bg-primary"
      >Haz click para ver los sub-indicadores</ButtonPlus
    >
    <div v-show="!status">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ButtonPlus from "@/components/ButtonPlus";

export default {
  name: "Subindicators",
  components: {
    ButtonPlus,
  },
  data() {
    return {
      status: true,
    };
  },
};
</script>

<style scoped></style>
