<template>
  <div class="container">
    <!-- actors table -->
    <div>
      <h2 class="ot-title-2">Tabla de actores</h2>

      <div
        id="filters"
        class="lg:sticky top-[100px] z-20 bg-white pb-6 -mx-10 px-10"
        :class="[showBorderFilters ? 'border-b-2' : '']"
      >
        <div class="">
          <div class="flex justify-end pt-1 text-gray-500 text-xs">
            <button
              @click="clearFilters"
              class="hover:text-gray-600 underline whitespace-nowrap"
            >
              Clear filters
            </button>
          </div>
          <!-- filters -->
          <div
            class="pt-2 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-8 items-center justify-center gap-6 md:gap-10 lg:pl-28 relative"
          >
            <DonutChart
              :data="donutTypeActors"
              @select="setType"
              :can-click="true"
              :selected="selectedType"
              class="lg:absolute w-28 md:w-20 mx-auto lg:left-16"
              font-size="12px"
              :labels-outside="true"
            />
            <div class="hidden lg:block"></div>
            <div class="">
              <div class="font-bold text-xs mb-1">Legitimidad</div>
              <Histogram
                :data="terActors"
                :metric="IDS.AC.LEG"
                :color-scale="tableColorScale"
              />
              <RangeSelector
                :min="legitimidadRange[0]"
                :max="legitimidadRange[1]"
                :value="legitimidad"
                @input="legitimidad = `${$event[0]}-${$event[1]}`"
              />
            </div>
            <div>
              <div class="font-bold text-xs mb-1">Poder</div>
              <Histogram
                :data="terActors"
                :metric="IDS.AC.POD"
                :color-scale="tableColorScale"
              />
              <RangeSelector
                :min="poderRange[0]"
                :max="poderRange[1]"
                :value="poder"
                @input="poder = `${$event[0]}-${$event[1]}`"
              />
            </div>
            <div>
              <div class="font-bold text-xs mb-1">Influencia</div>
              <Histogram
                :data="terActors"
                :metric="IDS.AC.INF"
                :color-scale="tableColorScale"
              />
              <RangeSelector
                :min="influenciaRange[0]"
                :max="influenciaRange[1]"
                :value="influencia"
                @input="influencia = `${$event[0]}-${$event[1]}`"
              />
            </div>
            <div class="">
              <div class="font-bold text-xs mb-1">Interés</div>
              <Histogram
                :data="terActors"
                :metric="IDS.AC.INT"
                :color-scale="tableColorScale"
              />
              <RangeSelector
                :min="interesRange[0]"
                :max="interesRange[1]"
                :value="interes"
                @input="interes = `${$event[0]}-${$event[1]}`"
              />
            </div>
            <div class="">
              <div class="font-bold text-xs mb-1">Conocimiento</div>
              <Histogram
                :data="terActors"
                :metric="IDS.AC.CON"
                :color-scale="tableColorScale"
              />
              <RangeSelector
                :min="conocimientoRange[0]"
                :max="conocimientoRange[1]"
                :value="conocimiento"
                @input="conocimiento = `${$event[0]}-${$event[1]}`"
              />
            </div>
            <div class="">
              <div class="font-bold text-xs mb-1">Prestigio</div>
              <Histogram
                :data="terActors"
                :metric="IDS.AC.PRE"
                :color-scale="tableColorScale"
              />
              <RangeSelector
                :min="prestigioRange[0]"
                :max="prestigioRange[1]"
                :value="prestigio"
                @input="prestigio = `${$event[0]}-${$event[1]}`"
              />
            </div>
            <div class="">
              <div class="font-bold text-xs mb-1 truncate">
                Cooperación requerida
              </div>
              <Histogram
                :data="terActors"
                :metric="IDS.AC.COO"
                :color-scale="tableColorScale"
              />
              <RangeSelector
                :min="coopRange[0]"
                :max="coopRange[1]"
                :value="coop"
                @input="coop = `${$event[0]}-${$event[1]}`"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- table -->
      <div class="overflow-x-auto py-1">
        <table class="table-fixed w-full" :style="{ minWidth: '700px' }">
          <thead>
            <tr>
              <th
                class="w-44 md:w-60 font-normal text-sm text-left text-gray-500 pl-5"
              ></th>
              <th class="ot-table-heading" @click="setOrder(IDS.AC.LEG)">
                <div class="flex justify-center items-center">
                  <span class="truncate">Legitimidad</span>
                  <svg
                    v-show="orderBy === IDS.AC.LEG"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 transform transition-transform"
                    :class="[orderDir ? '-rotate-90' : 'rotate-90']"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </div>
              </th>
              <th class="ot-table-heading" @click="setOrder(IDS.AC.POD)">
                <div class="flex justify-center items-center">
                  <span class="truncate">Poder</span>
                  <svg
                    v-show="orderBy === IDS.AC.POD"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 transform transition-transform"
                    :class="[orderDir ? '-rotate-90' : 'rotate-90']"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </div>
              </th>
              <th class="ot-table-heading" @click="setOrder(IDS.AC.INF)">
                <div class="flex justify-center items-center">
                  <span class="truncate">Influencia</span>
                  <svg
                    v-show="orderBy === IDS.AC.INF"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 transform transition-transform"
                    :class="[orderDir ? '-rotate-90' : 'rotate-90']"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </div>
              </th>
              <th class="ot-table-heading" @click="setOrder(IDS.AC.INT)">
                <div class="flex justify-center items-center">
                  <span class="truncate">Interés</span>
                  <svg
                    v-show="orderBy === IDS.AC.INT"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 transform transition-transform"
                    :class="[orderDir ? '-rotate-90' : 'rotate-90']"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </div>
              </th>
              <th class="ot-table-heading" @click="setOrder(IDS.AC.CON)">
                <div class="flex justify-center items-center">
                  <span class="truncate">Conocimiento</span>
                  <svg
                    v-show="orderBy === IDS.AC.CON"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 transform transition-transform"
                    :class="[orderDir ? '-rotate-90' : 'rotate-90']"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </div>
              </th>
              <th class="ot-table-heading" @click="setOrder(IDS.AC.PRE)">
                <div class="flex justify-center items-center">
                  <span class="truncate">Prestigio</span>
                  <svg
                    v-show="orderBy === IDS.AC.PRE"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 transform transition-transform"
                    :class="[orderDir ? '-rotate-90' : 'rotate-90']"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </div>
              </th>
              <th class="ot-table-heading" @click="setOrder(IDS.AC.COO)">
                <div class="flex justify-center items-center">
                  <span class="truncate">Cooperación requerida</span>
                  <svg
                    v-show="orderBy === IDS.AC.COO"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 transform transition-transform"
                    :class="[orderDir ? '-rotate-90' : 'rotate-90']"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="actor in displayedActors"
              :key="actor[IDS.AC.ID]"
              class="divide-x-2 divide-y-2 divide-white shadow rounded-md"
            >
              <td class="hover:bg-gray-100 rounded-l-md">
                <router-link
                  class="px-2 lg:px-4 py-2 flex justify-between items-center"
                  :to="{
                    path: `/${terId}/actores/${actor[IDS.AC.ID]}`,
                  }"
                  ><span class="truncate text-sm lg:text-base">{{
                    actor[IDS.AC.NAME]
                  }}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 lg:h-6 lg:w-6 text-gray-400 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    /></svg
                ></router-link>
              </td>
              <td
                class="ot-table-color-cell"
                :style="{
                  background: tableColorScale(actor[IDS.AC.LEG]),
                  color:
                    actor[IDS.AC.LEG] < 5 || actor[IDS.AC.LEG] > 25
                      ? 'white'
                      : null,
                }"
              >
                {{ actor[IDS.AC.LEG] }}
              </td>
              <td
                class="ot-table-color-cell"
                :style="{
                  background: tableColorScale(actor[IDS.AC.POD]),
                  color:
                    actor[IDS.AC.POD] < 5 || actor[IDS.AC.POD] > 25
                      ? 'white'
                      : null,
                }"
              >
                {{ actor[IDS.AC.POD] }}
              </td>
              <td
                class="ot-table-color-cell"
                :style="{
                  background: tableColorScale(actor[IDS.AC.INF]),
                  color:
                    actor[IDS.AC.INF] < 5 || actor[IDS.AC.INF] > 25
                      ? 'white'
                      : null,
                }"
              >
                {{ actor[IDS.AC.INF] }}
              </td>
              <td
                class="ot-table-color-cell"
                :style="{
                  background: tableColorScale(actor[IDS.AC.INT]),
                  color:
                    actor[IDS.AC.INT] < 5 || actor[IDS.AC.INT] > 25
                      ? 'white'
                      : null,
                }"
              >
                {{ actor[IDS.AC.INT] }}
              </td>
              <td
                class="ot-table-color-cell"
                :style="{
                  background: tableColorScale(actor[IDS.AC.CON]),
                  color:
                    actor[IDS.AC.CON] < 5 || actor[IDS.AC.CON] > 25
                      ? 'white'
                      : null,
                }"
              >
                {{ actor[IDS.AC.CON] }}
              </td>
              <td
                class="ot-table-color-cell"
                :style="{
                  background: tableColorScale(actor[IDS.AC.PRE]),
                  color:
                    actor[IDS.AC.PRE] < 5 || actor[IDS.AC.PRE] > 25
                      ? 'white'
                      : null,
                }"
              >
                {{ actor[IDS.AC.PRE] }}
              </td>
              <td
                class="ot-table-color-cell"
                :style="{
                  background: tableColorScale(actor[IDS.AC.COO]),
                  color:
                    actor[IDS.AC.COO] < 5 || actor[IDS.AC.COO] > 25
                      ? 'white'
                      : null,
                }"
              >
                {{ actor[IDS.AC.COO] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- table footer -->
      <div class="py-6 px-6 md:flex justify-between text-sm text-gray-700">
        <div class="flex items-center space-x-2 mb-4 md:mb-0">
          <div>
            Mostrando
            <span class="font-medium">{{ displayedActors.length }}</span>
            de
            <span class="font-medium">{{ terActors.length }}</span>
            resultados
          </div>
          <div v-if="displayedActors.length < terActors.length || showMore">
            -
          </div>
          <button
            v-if="!showMore && displayedActors.length < terActors.length"
            @click="showMore = true"
            class="text-gray-500 underline hover:text-gray-600"
          >
            Ver más
          </button>
          <button
            v-if="showMore && displayedActors.length > 10"
            @click="showMore = false"
            class="text-gray-500 underline hover:text-gray-600"
          >
            Ver menos
          </button>
        </div>
        <div class="w-72">
          <div class="relative">
            <GradientLegend
              class="h-3 transform rotate-180"
              :color-scale="divergingScale"
            />
            <div
              class="absolute h-4 bg-gray-900"
              :style="{
                width: '2px',
                top: '-4px',
                left: 'calc(50% - 1px)',
              }"
            ></div>
          </div>
          <div class="flex justify-between text-xs">
            <div>30 - Alto</div>
            <div>15 - Medio</div>
            <div>0 - Nulo</div>
          </div>
        </div>
      </div>

      <!-- scatter plot -->
      <div class="mt-16 lg:mt-20">
        <h2 class="ot-title-2">
          Correlación entre indicadores
        </h2>

        <div class="flex md:justify-end mb-3 lg:mb-0">
          <div>
            <div class="text-sm">Ver nombre</div>
            <ButtonSwitch
              :options="[
                { name: 'Sí', value: true },
                { name: 'No', value: false },
              ]"
              v-model="showLabelsScatter"
              class="flex-shrink-0 bg-gray-100 p-1 shadow-inner text-xs rounded-full print:hidden border"
              item-class="text-gray-600 rounded-full hover:text-gray-700 focus:text-gray-700 transition font-medium py-2 px-4 bg-transparent focus:outline-none truncate sm:overflow-visible w-24 sm:w-auto"
              item-active-class="text-primary-700 hover:text-primary-700 focus:text-primary-800 ring-transparent ring-0"
            />
          </div>
        </div>

        <div
          class="flex items-center space-x-6 overflow-x-auto md:overflow-visible pb-6 md:pb-0 pl-6 md:pl-0"
        >
          <div class="flex-grow">
            <div class="">
              <select v-model="yIndicator" class="mb-8 text-sm font-medium">
                <option
                  v-for="indicator in mainIndicators"
                  :key="indicator"
                  :value="indicator"
                  >{{ indicator }}</option
                >
              </select>
              <ScatterPlot
                :data="scatterPlotData"
                :x-domain="[0, 30]"
                :y-domain="[0, 30]"
                :r-domain="[0, 30]"
                :dot-size="[10, 30]"
                :fill-opacity="0.7"
                :show-labels="showLabelsScatter"
                :color-scale="tableColorScale"
                class="pl-4"
                :style="{ height: '530px', minWidth: '530px' }"
                v-slot="{ tooltipData }"
              >
                <div class="font-bold text-base mb-1">
                  {{ tooltipData.name }}
                </div>
                <div class="text-sm">
                  <div>{{ yIndicator }}: {{ tooltipData.y }}</div>
                  <div>{{ xIndicator }}: {{ tooltipData.x }}</div>
                  <div>{{ sizeIndicator }}: {{ tooltipData.value }}</div>
                </div>
              </ScatterPlot>
            </div>
            <div class="flex justify-center mt-6">
              <select class="text-sm font-medium" v-model="xIndicator">
                <option
                  v-for="indicator in mainIndicators"
                  :key="indicator"
                  :value="indicator"
                  >{{ indicator }}</option
                >
              </select>
            </div>
          </div>
          <!-- size selector -->
          <div>
            <label class="block text-xs text-gray-500" for="size"
              >Tamaño:</label
            >
            <select
              class="text-sm font-medium"
              id="size"
              v-model="sizeIndicator"
            >
              <option
                v-for="indicator in mainIndicators"
                :key="indicator"
                :value="indicator"
                >{{ indicator }}</option
              >
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScatterPlot from "@/components/ScatterPlot";
import DonutChart from "@/components/DonutChart";
// import LegendColor from "@/components/LegendColor";
import Histogram from "@/components/Histogram";
import RangeSelector from "@/components/RangeSelector";
import GradientLegend from "@/components/GradientLegend";
import ButtonSwitch from "@/components/ButtonSwitch";

import * as d3 from "d3";

import api from "@/services/data";
import colors from "@/utils/colors";
import * as IDS from "@/utils/ids";
// import { groupBy } from "@/utils/helpers";

export default {
  name: "Home",
  components: {
    ScatterPlot,
    DonutChart,
    // LegendColor,
    Histogram,
    RangeSelector,
    GradientLegend,
    ButtonSwitch,
  },
  data() {
    return {
      actors: [],
      territories: [],
      indicators: [],
      binaryIndicators: [],
      mainIndicators: [],
      mapColors: {
        min: colors.gradientMin,
        max: colors.gradientMax,
      },
      actorTypes: [],
      actorProfiles: [],
      yIndicator: IDS.AC.INF,
      xIndicator: IDS.AC.POD,
      sizeIndicator: IDS.AC.LEG,
      selectedType: null,
      scatterPlotColor: colors.primary,
      grayColor: "#E5E7EB",
      legitimidad: "0-30",
      poder: "0-30",
      influencia: "0-30",
      interes: "0-30",
      prestigio: "0-30",
      conocimiento: "0-30",
      coop: "0-30",
      hoveredTerritory: null,
      orderBy: IDS.AC.LEG,
      orderDir: false,
      showMore: false,
      showBorderFilters: false,
      divergingScale: [colors.low, colors.middle, colors.high],
      IDS,
      showLabelsScatter: false,
    };
  },
  computed: {
    // territory param
    terId() {
      return this.$route.params.terId || null;
    },
    // actors filtered by selected territory
    terActors() {
      return this.terId
        ? this.actors.filter((e) => this.terId === e[IDS.AC.TER])
        : this.actors;
    },
    // types of actors in selected territory
    donutTypeActors() {
      const intern = this.terActors.filter((e) => e[IDS.AC.TYPE] === "Interno")
        .length;
      const extern = this.terActors.filter((e) => e[IDS.AC.TYPE] === "Externo")
        .length;

      return [
        {
          name: "Interno",
          value: (intern / this.terActors.length) * 100,
          count: intern,
          color: colors.intern,
        },
        {
          name: "Externo",
          value: (extern / this.terActors.length) * 100,
          count: extern,
          color: colors.extern,
        },
      ];
    },
    tableColorScale() {
      return d3
        .scaleDiverging()
        .domain([0, 15, 30])
        .interpolator(
          d3.interpolateRgbBasis([colors.low, colors.middle, colors.high])
        );
    },
    // all actors that match filters
    filteredActors() {
      let data = [...this.terActors];

      if (this.selectedType) {
        data = data.filter((e) => e[IDS.AC.TYPE] === this.selectedType);
      }

      data = this.filterRange(
        data,
        IDS.AC.LEG,
        this.legitimidadRange,
        this.legitimidad
      );

      data = this.filterRange(data, IDS.AC.POD, this.poderRange, this.poder);

      data = this.filterRange(
        data,
        IDS.AC.INF,
        this.influenciaRange,
        this.influencia
      );

      data = this.filterRange(
        data,
        IDS.AC.INT,
        this.interesRange,
        this.interes
      );

      data = this.filterRange(
        data,
        IDS.AC.CON,
        this.conocimientoRange,
        this.conocimiento
      );

      data = this.filterRange(
        data,
        IDS.AC.PRE,
        this.prestigioRange,
        this.prestigio
      );

      data = this.filterRange(data, IDS.AC.COO, this.coopRange, this.coop);

      data.sort((a, b) => {
        if (this.orderBy) {
          if (a[this.orderBy] < b[this.orderBy]) return this.orderDir ? -1 : 1;
          if (a[this.orderBy] > b[this.orderBy]) return this.orderDir ? 1 : -1;
          else 0;
        } else {
          if (a[IDS.AC.NAME] < b[IDS.AC.NAME]) return -1;
          if (a[IDS.AC.NAME] > b[IDS.AC.NAME]) return 1;
          else 0;
        }
      });

      return data;
    },
    // actors currently shown in table
    displayedActors() {
      return this.showMore
        ? this.filteredActors
        : this.filteredActors.slice(0, 10);
    },
    scatterPlotData() {
      return this.terActors
        .map((e) => {
          return {
            id: e[IDS.AC.ID],
            name: e[IDS.AC.NAME],
            x: e[this.xIndicator],
            y: e[this.yIndicator],
            value: e[this.sizeIndicator],
            color: this.filteredActors.find(
              (d) => e[IDS.AC.ID] === d[IDS.AC.ID]
            )
              ? this.scatterPlotColorScale(
                  // calculate distance from lower left corner (0) with pythagoras
                  Math.sqrt(e[this.xIndicator] ** 2 + e[this.yIndicator] ** 2)
                )
              : // grey if not selected type
                this.grayColor,
          };
        })
        .sort((a, b) => b.value - a.value);
    },
    scatterPlotColorScale() {
      // set max equal to longest side of 30x30 triangle using pythagoras
      const max = Math.sqrt(30 ** 2 + 30 ** 2);

      return d3
        .scaleDiverging()
        .domain([0, max / 2, max])
        .interpolator(
          d3.interpolateRgbBasis([colors.low, colors.middle, colors.high])
        );
    },
    legitimidadRange() {
      return [
        d3.min(this.terActors, (e) => +e[IDS.AC.LEG]),
        d3.max(this.terActors, (e) => +e[IDS.AC.LEG]),
      ];
    },
    poderRange() {
      return [
        d3.min(this.terActors, (e) => +e[IDS.AC.POD]),
        d3.max(this.terActors, (e) => +e[IDS.AC.POD]),
      ];
    },
    influenciaRange() {
      return [
        d3.min(this.terActors, (e) => +e[IDS.AC.INF]),
        d3.max(this.terActors, (e) => +e[IDS.AC.INF]),
      ];
    },
    interesRange() {
      return [
        d3.min(this.terActors, (e) => +e[IDS.AC.INT]),
        d3.max(this.terActors, (e) => +e[IDS.AC.INT]),
      ];
    },
    conocimientoRange() {
      return [
        d3.min(this.terActors, (e) => +e[IDS.AC.CON]),
        d3.max(this.terActors, (e) => +e[IDS.AC.CON]),
      ];
    },
    prestigioRange() {
      return [
        d3.min(this.terActors, (e) => +e[IDS.AC.PRE]),
        d3.max(this.terActors, (e) => +e[IDS.AC.PRE]),
      ];
    },
    coopRange() {
      return [
        d3.min(this.terActors, (e) => +e[IDS.AC.COO]),
        d3.max(this.terActors, (e) => +e[IDS.AC.COO]),
      ];
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.error = false;

        this.actors = await api.getActors();
        this.territories = await api.getTerritories();
        this.indicators = await api.getIndicators();

        this.mainIndicators = [
          ...new Set(this.indicators.map((e) => e[IDS.IND.MAIN])),
        ];
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    filterRange(data, prop, range, sliderValues) {
      const [min, max] = sliderValues.split("-");

      if (+min !== range[0] || +max !== range[1]) {
        data = data.filter((e) => {
          return +e[prop] >= +min && +e[prop] <= +max;
        });
      }
      return data;
    },
    clearFilters() {
      this.legitimidad = `${this.legitimidadRange[0]}-${this.legitimidadRange[1]}`;
      this.poder = `${this.poderRange[0]}-${this.poderRange[1]}`;
      this.influencia = `${this.influenciaRange[0]}-${this.influenciaRange[1]}`;
      this.interes = `${this.interesRange[0]}-${this.interesRange[1]}`;
      this.conocimiento = `${this.conocimientoRange[0]}-${this.conocimientoRange[1]}`;
      this.prestigio = `${this.prestigioRange[0]}-${this.prestigioRange[1]}`;
      this.coop = `${this.coopRange[0]}-${this.coopRange[1]}`;
      this.selectedType = null;
    },
    setType(val) {
      if (this.selectedType === val.data.name) this.selectedType = null;
      else this.selectedType = val.data.name;
    },
    setOrder(val) {
      // Change direction if same metric is clicked again
      // or go back to default
      if (val === this.orderBy) {
        if (!this.orderDir) this.orderDir = !this.orderDir;
        else {
          this.orderBy = null;
          this.orderDir = false;
        }
      } else this.orderBy = val;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style></style>
