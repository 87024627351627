<template>
  <div
    class="ot-tooltip absolute pointer-events-none"
    :class="{
      'opacity-100 ': show,
      'opacity-0 ': !show,
    }"
  >
    <!-- slot for inner content -->
    <div
      ref="slot"
      class="bg-white border border-gray-800 rounded-lg text-xs"
      :class="{
        'rounded-full p-1 transform translate-y-px': rounded,
        'px-4 py-4': !rounded,
      }"
    >
      <slot></slot>
    </div>
    <!-- tooltip arrow -->
    <div ref="arrow" class="ot-tooltip-arrow"></div>
  </div>
</template>

<script>
// https://popper.js.org/docs/v2/constructors/
import { createPopper } from "@popperjs/core";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    html: {
      type: [String, Number],
      default: "",
    },
    left: {
      type: Number,
      default: 0,
    },
    top: {
      type: Number,
      default: 0,
    },
    // width: {
    //   type: Number,
    //   default: 220,
    // },
    height: {
      type: Number,
      default: 25,
    },
    target: {
      type: null,
      default: null,
    },
    placement: {
      type: String,
      default: "top",
    },
    followMouse: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      width: 100,
      // used to make tooltip follow the mouse instead of positioned on an element
      virtualElement: {
        getBoundingClientRect: this.generateGetBoundingClientRect(),
      },
    };
  },
  watch: {
    target() {
      this.createPopper();
    },
  },
  mounted() {
    this.createPopper();
    // position virtual element to make tooltip follow the mouse
    if (this.followMouse) {
      document.addEventListener("mousemove", ({ clientX: x, clientY: y }) => {
        this.virtualElement.getBoundingClientRect = this.generateGetBoundingClientRect(
          x,
          y
        );
        this.instance.update();
      });
    }
  },
  methods: {
    // configure and create new popper instance
    createPopper() {
      this.setWidth();
      if (this.followMouse) {
        this.instance = createPopper(this.virtualElement, this.$el, {
          placement: this.placement,
          modifiers: [
            {
              name: "flip",
              enabled: false,
            },
            {
              name: "arrow",
              options: {
                element: this.$refs.arrow,
                padding: 5,
              },
            },
            {
              name: "offset",
              options: {
                offset: [0, 15],
              },
            },
          ],
        });
      }
      if (this.target) {
        this.instance = createPopper(this.target, this.$el, {
          placement: this.placement,
          modifiers: [
            {
              name: "flip",
              enabled: false,
            },
            {
              name: "arrow",
              options: {
                element: this.$refs.arrow,
                padding: 5,
              },
            },
            {
              name: "offset",
              options: {
                offset: [0, 5],
              },
            },
          ],
        });
      }
    },
    setWidth() {
      this.width = this.$refs.slot.clientWidth;
    },
    update() {
      if (this.instance) this.instance.update();
    },
    generateGetBoundingClientRect(x = 0, y = 0) {
      return () => ({
        width: 0,
        height: 0,
        top: y,
        right: x,
        bottom: y,
        left: x,
      });
    },
  },
};
</script>
<style>
.ot-tooltip {
  z-index: 30;
  max-width: 24rem;
}
.ot-tooltip .ot-tooltip-arrow {
  @apply border-gray-800;
}
/* .ot-tooltip .ot-tooltip-inner {
  @apply bg-black bg-opacity-80 text-white px-4 py-2 rounded-lg text-xs;
} */

/* top */
.ot-tooltip .ot-tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}
</style>
