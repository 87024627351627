<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    v-show="isOpen"
    class="fixed z-10 inset-0 overflow-y-auto"
    :class="{ 'z-50': isOpen }"
  >
    <div
      class="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.
      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <transition name="tw-modal-backdrop">
        <div
          v-show="isOpen"
          class="fixed inset-0 transition-opacity"
          aria-hidden="true"
          @click="$emit('close')"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <!--
      Modal panel, show/hide based on modal state.
      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <transition name="tw-modal">
        <div
          v-show="isOpen"
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:mt-20 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6 sm:pr-8"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              @click="$emit('close')"
            >
              <span class="sr-only">Close</span>
              <!-- Heroicon name: x -->
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="">
            <div class="mt-3 text-left ">
              <h3 class="text-lg leading-6 font-bold text-gray-900">
                <slot name="header"> </slot>
              </h3>
              <slot name="subtitle"></slot>

              <div class="mt-6 text-sm text-gray-900 text-left">
                <slot name="body"> </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
/* BACKDROP */
.tw-modal-backdrop-enter-active {
  @apply ease-out duration-300;
}
.tw-modal-backdrop-enter {
  @apply opacity-0;
}
.tw-modal-backdrop-enter-to {
  @apply opacity-100;
}
.tw-modal-backdrop-leave-active {
  @apply ease-out duration-1000;
}
.tw-modal-backdrop-leave {
  @apply opacity-100;
}
.tw-modal-backdrop-leave-to {
  @apply opacity-0;
}
/* CARD */
.tw-modal-enter-active {
  @apply ease-out duration-300;
}
.tw-modal-enter {
  @apply opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95;
}
.tw-modal-enter-to {
  @apply opacity-100 translate-y-0 sm:scale-100;
}
.tw-modal-leave-active {
  @apply ease-out duration-1000;
}
.tw-modal-leave {
  @apply opacity-100 translate-y-0 sm:scale-100;
}
.tw-modal-leave-to {
  @apply opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95;
}
</style>
