<template>
  <div>
    <div ref="container" class="scatter-plot-container h-full">
      <svg ref="svg" class="overflow-visible text-gray-600" />
    </div>
    <VTooltip :show="tooltip.show" :target="tooltip.target">
      <slot :tooltipData="tooltip.data">
        <div class="font-bold text-base">{{ tooltip.name }}</div>
        <div>{{ tooltip.value }}</div>
      </slot>
    </VTooltip>
  </div>
</template>

<script>
import * as d3 from "d3";
import VTooltip from "@/components/VTooltip";

export default {
  name: "ScatterPlot",
  components: {
    VTooltip,
  },
  props: {
    data: {
      type: Array,
      default: () => [
        { id: "Name 1", x: 10, y: 20, value: 1 },
        { id: "Name 2", x: 20, y: 30, value: 1 },
        { id: "Name 3", x: 5, y: 10, value: 1 },
        { id: "Name 4", x: 20, y: 15, value: 1 },
        { id: "Name 5", x: 30, y: 30, value: 1 },
        { id: "Name 6", x: 30, y: 20, value: 1 },
        { id: "Name 7", x: 40, y: 35, value: 1 },
        { id: "Name 8", x: 45, y: 25, value: 1 },
        { id: "Name 9", x: 50, y: 30, value: 1 },
        { id: "Name 10", x: 55, y: 40, value: 1 },
      ],
    },
    margin: {
      type: Object,
      default: () => ({ top: 0, right: 0, bottom: 0, left: 0 }),
    },
    dotSize: {
      type: Array,
      default: () => [5, 5],
    },
    color: {
      type: String,
      default: "#6B7280",
    },
    gridColor: {
      type: String,
      default: "#E5E7EB",
    },
    refLineColor: {
      type: String,
      default: "#6B7280",
    },
    strokeDasharray: {
      type: String,
      default: "3,2",
    },
    labelxAxis: {
      type: String,
      default: "x axis label",
    },
    labelyAxis: {
      type: String,
      default: "y axis label",
    },
    ease: {
      type: String,
      default: "easeCubic",
    },
    duration: {
      type: Number,
      default: 300,
    },
    tickFormat: {
      type: Function,
      default: null,
    },
    // if not passed, the data extent will be used
    xDomain: {
      type: Array,
      default: null,
    },
    // if not passed, the data extent will be used
    yDomain: {
      type: Array,
      default: null,
    },
    // if not passed, the data extent will be used
    rDomain: {
      type: Array,
      default: null,
    },
    xRef: {
      type: Object,
      default: null, // { value: 10, label: 'median' }
    },
    yRef: {
      type: Object,
      default: null,
    },
    forceZeroY: {
      type: Boolean,
      default: false,
    },
    forceZeroX: {
      type: Boolean,
      default: false,
    },
    colorQuadrants: {
      type: Boolean,
      default: true,
    },
    fillOpacity: {
      type: Number,
      default: 1,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    colorScale: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      width: 500,
      height: 500,
      tooltip: {
        show: false,
        name: "",
        value: null,
        data: {},
      },
    };
  },
  computed: {
    xExtent() {
      const data = [...this.data];
      if (this.xRef) data.push({ x: this.xRef.value });
      if (this.forceZeroX) data.push({ x: 0 });
      return this.xDomain || d3.extent(data, (d) => d.x);
    },
    yExtent() {
      const data = [...this.data];
      if (this.yRef) data.push({ y: this.yRef.value });
      if (this.forceZeroY) data.push({ y: 0 });
      return this.yDomain || d3.extent(data, (d) => d.y);
    },
    x() {
      return d3
        .scaleLinear()
        .domain(this.xExtent)
        .range([this.margin.left, this.width - this.margin.right])
        .nice();
    },
    y() {
      return d3
        .scaleLinear()
        .domain(this.yExtent)
        .range([this.height - this.margin.bottom, this.margin.top])
        .nice();
    },
    // scale function for circle radius
    r() {
      const domain = this.rDomain || d3.extent(this.data, (d) => d.value);

      return d3
        .scaleSqrt()
        .domain(domain)
        .range(this.dotSize);
    },
  },
  watch: {
    data() {
      this.draw();
    },
    showLabels(newVal) {
      this.svg
        .select(".labels")
        .selectAll("text")
        .attr("display", newVal ? null : "none");
    },
  },
  mounted() {
    this.setSize();
    this.createChart();
    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      this.resizeObserver.observe(this.$refs.container);
    } else {
      // for IE
      window.addEventListener("resize", this.resize);
    }
  },
  beforeUnmount() {
    if (window.ResizeObserver) {
      this.resizeObserver.unobserve(this.$refs.container);
    } else {
      // for IE
      window.removeEventListener("resize", this.resize);
    }
  },
  methods: {
    setSize() {
      const container = this.$refs.container;
      if (container) {
        this.width = container.clientWidth;
        this.height = container.clientHeight;
      }
    },
    resize() {
      const currentWidth = this.width;
      const currentHeight = this.height;
      this.setSize();
      if (currentWidth !== this.width || currentHeight !== this.height) {
        this.updateChart();
      }
    },
    createChart() {
      this.svg = d3
        .select(this.$refs.svg)
        .attr("viewBox", [0, 0, this.width, this.height]);
      // if (this.colorQuadrants) {
      //   this.upperLeftQuadrant = this.svg
      //     .append("rect")
      //     .attr("class", "quadrant")
      //     .attr(
      //       "transform",
      //       `translate(${this.margin.left}, ${this.margin.top})`
      //     )
      //     .attr("width", this.x(this.xRef.value) - this.margin.left)
      //     .attr("height", this.y(this.yRef.value) - this.margin.top)
      //     .attr("fill", "blue");
      // }
      this.xAxis = this.svg.append("g").attr("class", "x-axis");
      this.yAxis = this.svg.append("g").attr("class", "y-axis");
      this.circles = this.svg.append("g").attr("class", "circles");
      if (this.yRef) {
        this.yRefLine = this.svg.append("g").attr("class", "y-ref-line");
        this.yRefLine
          .append("line")
          .attr("stroke", this.refLineColor)
          .attr("stroke-dasharray", this.strokeDasharray)
          .attr("x1", this.margin.left)
          .attr("x2", this.width - this.margin.right)
          .attr("y1", this.y(this.yRef.value))
          .attr("y2", this.y(this.yRef.value));
        if (this.yRef.label) {
          this.yRefLine
            .append("text")
            .text(this.yRef.label)
            .attr("x", this.width - this.margin.right - 5)
            .attr("y", this.y(this.yRef.value) - 5)
            .attr("text-anchor", "end")
            .attr("fill", "currentColor")
            .attr("font-size", "0.75rem");
        }
      }
      if (this.xRef) {
        this.xRefLine = this.svg.append("g").attr("class", "x-ref-line");
        this.xRefLine
          .append("line")
          .attr("stroke", this.refLineColor)
          .attr("stroke-dasharray", this.strokeDasharray)
          .attr("x1", this.x(this.xRef.value))
          .attr("x2", this.x(this.xRef.value))
          .attr("y1", this.height - this.margin.bottom)
          .attr("y2", this.margin.top);
        if (this.xRef.label) {
          this.xRefLine
            .append("text")
            .text(this.xRef.label)
            .attr("text-anchor", "end")
            .attr("transform", `rotate(-90 ${this.x(this.xRef.value) - 5} 0)`)
            .attr("fill", "currentColor")
            .attr("font-size", "0.75rem")
            .attr("x", this.x(this.xRef.value) - 10)
            .attr("y", this.margin.top);
        }
      }

      this.labels = this.svg.append("g").attr("class", "labels");

      //first paint with no delay
      this.draw(0);
    },
    draw(delay = this.duration, duration = this.duration) {
      const selection = this.circles
        .selectAll("circle")
        .data(this.data, (d) => d.id);
      // calculate delays
      const updating = selection;
      // const exiting = selection.exit();
      const updateDelay1 = 0;
      const updateDelay = updateDelay1 + updating.size() ? updateDelay1 : 0;
      const enterDelay = updateDelay + delay;
      const enterDelayAxis = updateDelay;
      // AXIS
      this.drawAxis(enterDelayAxis, duration);
      // DOTS
      selection.join(
        (enter) =>
          enter
            .append("circle")
            .attr("r", 0)
            .attr("fill", (d) => {
              if (d.color) return d.color;
              if (this.colorScale) {
                // calculate distance from lower left corner (0) with pythagoras
                const distance = Math.sqrt(this.x(d.x) ** 2 + this.y(d.y) ** 2);
                return this.colorScale(distance);
              } else return this.color;
            })
            .attr("fill-opacity", this.fillOpacity)
            .attr("cx", (d) => this.x(d.x))
            .attr("cy", (d) => this.y(d.y))
            .on("mouseenter", (event, data) => {
              this.showTooltip(event, data);
              this.$emit("hover", { event, data });
            })
            .on("mouseleave", () => {
              this.$emit("hover", null);
              this.hideTooltip();
            })
            .call((enter) =>
              enter
                .transition()
                .duration(duration)
                .ease(d3[this.ease])
                .delay(enterDelay)
                .attr("r", (d) => this.r(d.value))
            ),
        (update) =>
          update.call((update) =>
            update
              .transition()
              .duration(duration)
              .ease(d3[this.ease])
              .delay(updateDelay)
              .attr("cx", (d) => this.x(d.x))
              .attr("cy", (d) => this.y(d.y))
              .attr("r", (d) => this.r(d.value))
              .attr("fill", (d) => (d.color ? d.color : this.color))
              .attr("fill-opacity", this.fillOpacity)
          ),
        (exit) =>
          exit
            .transition()
            .duration(duration)
            .ease(d3[this.ease])
            .attr("r", 0)
            .remove()
      );
      // REF LINES
      this.drawRefLines(updateDelay, duration);

      // LABELS
      this.labels
        .selectAll("text")
        .data(this.data, (d) => d.id)
        .join("text")
        .attr("text-anchor", "middle")
        .attr("font-size", "11px")
        .attr("x", (d) => this.x(d.x))
        .attr("y", (d) => this.y(d.y))
        .attr("display", this.showLabels ? "block" : "none")
        .text((d) => d.name);
    },
    drawAxis(delay, duration) {
      this.xAxis
        .attr("transform", `translate(0, ${this.margin.top})`)
        .transition()
        .duration(duration)
        .ease(d3[this.ease])
        .delay(delay)
        .call(
          d3
            .axisBottom(this.x)
            .tickSize(this.height - this.margin.top - this.margin.bottom)
            .tickFormat(this.tickFormat)
        );
      this.yAxis
        .attr("transform", `translate(${this.width - this.margin.right}, 0)`)
        .transition()
        .duration(duration)
        .ease(d3[this.ease])
        .delay(delay)
        .call(
          d3
            .axisLeft(this.y)
            .tickSize(this.width - this.margin.left - this.margin.right)
            .tickFormat(this.tickFormat)
        );
      this.svg
        .selectAll(".tick")
        .select("line")
        .attr("stroke", this.gridColor);
    },
    drawRefLines(delay, duration) {
      if (this.yRefLine) {
        this.yRefLine
          .select("line")
          .transition()
          .duration(duration)
          .delay(delay)
          .attr("x1", this.margin.left)
          .attr("x2", this.width - this.margin.right)
          .attr("y1", this.y(this.yRef.value))
          .attr("y2", this.y(this.yRef.value));
        if (this.yRef.label) {
          this.yRefLine
            .select("text")
            .transition()
            .duration(duration)
            .delay(delay)
            .attr("x", this.width - this.margin.right - 5)
            .attr("y", this.y(this.yRef.value) - 5);
        }
      }
      if (this.xRefLine) {
        this.xRefLine
          .select("line")
          .transition()
          .duration(duration)
          .delay(delay)
          .attr("x1", this.x(this.xRef.value))
          .attr("x2", this.x(this.xRef.value))
          .attr("y1", this.height - this.margin.bottom)
          .attr("y2", this.margin.top);
        if (this.xRef.label) {
          this.xRefLine
            .select("text")
            .transition()
            .duration(duration)
            .delay(delay)
            .attr("transform", `rotate(-90 ${this.x(this.xRef.value) - 5} 0)`)
            .attr("x", this.x(this.xRef.value) - 10)
            .attr("y", this.margin.top);
        }
      }
    },
    // called on screen resize only. No animations.
    updateChart() {
      this.svg.attr("viewBox", [0, 0, this.width, this.height]);
      this.draw(0, 0);
    },
    showTooltip(e, data) {
      this.tooltip.name = data.name;
      this.tooltip.target = e.target;
      this.tooltip.value = data.value;
      this.tooltip.data = data;
      this.tooltip.show = true;
    },
    hideTooltip() {
      this.tooltip.show = false;
    },
  },
};
</script>

<style>
.scatter-plot-container .domain {
  display: none;
}
</style>
