import axios from "axios";
import { csvParse, autoType } from "d3";

import panama from "@/assets/data/panama_indimap_25.json";

import * as IDS from "@/utils/ids";

const url = {
  actors:
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vQduiI9eU7kpmMfiXZ-sdQg48gWbrMT-Hsp9ypZNXwjVGV2-GXmyOdwrRM92V3KjlP7-onKrHuR__HF/pub?gid=1914207229&single=true&output=csv",
  territories:
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vQduiI9eU7kpmMfiXZ-sdQg48gWbrMT-Hsp9ypZNXwjVGV2-GXmyOdwrRM92V3KjlP7-onKrHuR__HF/pub?gid=252651739&single=true&output=csv",
  projects:
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vQduiI9eU7kpmMfiXZ-sdQg48gWbrMT-Hsp9ypZNXwjVGV2-GXmyOdwrRM92V3KjlP7-onKrHuR__HF/pub?gid=1791276577&single=true&output=csv",
  indicators:
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vQduiI9eU7kpmMfiXZ-sdQg48gWbrMT-Hsp9ypZNXwjVGV2-GXmyOdwrRM92V3KjlP7-onKrHuR__HF/pub?gid=481092716&single=true&output=csv",
  parties:
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vQduiI9eU7kpmMfiXZ-sdQg48gWbrMT-Hsp9ypZNXwjVGV2-GXmyOdwrRM92V3KjlP7-onKrHuR__HF/pub?gid=1192833122&single=true&output=csv",
  positions:
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vQduiI9eU7kpmMfiXZ-sdQg48gWbrMT-Hsp9ypZNXwjVGV2-GXmyOdwrRM92V3KjlP7-onKrHuR__HF/pub?gid=856824838&single=true&output=csv",
  texts:
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vQduiI9eU7kpmMfiXZ-sdQg48gWbrMT-Hsp9ypZNXwjVGV2-GXmyOdwrRM92V3KjlP7-onKrHuR__HF/pub?gid=1826359015&single=true&output=csv",
};

let actors = null;
let fetched = false;

let territories = null;
let fetchedTerritories = false;

let projects = null;
let fetchedProjects = false;

let indicators = null;
let fetchedIndicators = false;

let parties = null;
let fetchedParties = false;

let positions = null;
let fetchedPositions = false;

let texts = null;
let fetchedTexts = false;

export default {
  async getActors() {
    if (!fetched) {
      const csv = (await axios.get(url.actors)).data;

      fetched = true;

      actors = csvParse(csv, autoType);
    }
    return actors;
  },

  async getTerritories() {
    if (!fetchedTerritories) {
      const csv = (await axios.get(url.territories)).data;

      fetchedTerritories = true;

      territories = csvParse(csv);
    }
    return territories;
  },

  async getProjects() {
    if (!fetchedProjects) {
      const csv = (await axios.get(url.projects)).data;

      fetchedProjects = true;

      projects = csvParse(csv);
    }
    return projects;
  },

  async getIndicators() {
    if (!fetchedIndicators) {
      const csv = (await axios.get(url.indicators)).data;

      fetchedIndicators = true;

      indicators = csvParse(csv);
    }
    return indicators;
  },

  async getParties() {
    if (!fetchedParties) {
      const csv = (await axios.get(url.parties)).data;

      fetchedParties = true;

      parties = csvParse(csv, (d) => {
        d[IDS.PAR.COUNT] = d[IDS.PAR.COUNT].replace(",", "");
        return autoType(d);
      });
    }
    return parties;
  },

  async getPositions() {
    if (!fetchedPositions) {
      const csv = (await axios.get(url.positions)).data;

      fetchedPositions = true;

      positions = csvParse(csv);
    }
    return positions;
  },

  async getMap() {
    if (!fetchedTerritories) {
      await this.getTerritories();
    }

    // add territory ids to properties in topojson
    panama.objects.panama_indimap.geometries.forEach((e) => {
      const territory =
        territories.find((d) => d.map === e.properties.Name) || {};
      e.properties.id = territory[IDS.TER.ID];
      e.properties.name = territory[IDS.TER.NAME];
    });

    return panama;
  },

  async getTexts() {
    if (!fetchedTexts) {
      const csv = (await axios.get(url.texts)).data;

      fetchedTexts = true;

      texts = csvParse(csv);
    }
    return texts;
  },
};
