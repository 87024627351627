<template>
  <button
    class="group text-left w-full flex items-center rounded-md px-4 py-2 bg-gray-100 hover:bg-gray-100 hover:ring-2 hover:ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 transition text-sm md:text-base"
    @click="$emit('click', $event)"
  >
    <span
      class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white mr-2 transition"
      :class="[buttonClass]"
    >
      <svg
        class="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <!-- Heroicon name: solid/plus -->
        <path
          v-if="status"
          fill-rule="evenodd"
          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
          clip-rule="evenodd"
        />
        <!-- Heroicon name: solid/minus -->
        <path
          v-else
          fill-rule="evenodd"
          d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
          clip-rule="evenodd"
        />
      </svg>
    </span>
    <slot></slot>
  </button>
</template>
<script>
export default {
  props: {
    status: {
      type: Boolean,
      default: true,
    },
    buttonClass: {
      type: String,
      default: "bg-c2 group-hover:bg-c1",
    },
  },
};
</script>
