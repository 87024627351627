<template>
  <div ref="container" class="">
    <svg ref="svg" :width="width" :height="height" class="overflow-visible" />
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "RadarChart",
  props: {
    data: {
      type: Array,
      default: () => [
        { name: "Axis 1", value: 10 },
        { name: "Axis 2", value: 30 },
        { name: "Axis 3", value: 20 },
        { name: "Axis 4", value: 50 },
        { name: "Axis 5", value: 20 },
      ],
    },
    levels: {
      type: Array,
      default: () => [10, 20, 30, 40, 50],
    },
    gridColor: {
      type: String,
      default: "#D1D5DB", // gray-300
    },
    shapeColor: {
      type: String,
      default: "grey",
    },
    margin: {
      type: Number,
      default: 20,
    },
    labelColor: {
      type: String,
      default: "#4B5563", // gray-800
    },
    labelFactor: {
      type: Number,
      default: 1.2, // to multiply max radius for positioning labels
    },
    unit: {
      type: String,
      default: "", // for labels on axis levels
    },
    rotation: {
      type: Number,
      default: 0, // (Math.PI * 2) / 10, // rotation in radians (to change position of axes)
    },
  },
  data() {
    return {
      width: 250,
      height: 250,
    };
  },
  computed: {
    // angle of each slice in radians
    angle() {
      return (Math.PI * 2) / this.data.length;
    },
    // length of each axis
    radius() {
      return Math.min(this.width, this.height) / 2 - this.margin; // half of smallest side
    },
    max() {
      // return Math.max(...this.data.map((e) => e.value));
      return this.levels[this.levels.length - 1];
    },
    // scale function for radius
    r() {
      return d3
        .scaleLinear()
        .range([0, this.radius - this.margin])
        .domain([0, this.max]);
    },
    // function to create path data for radar shape
    radialLine() {
      return d3
        .lineRadial()
        .radius((d) => this.r(d.value))
        .angle((d, i) => i * this.angle + this.rotation)
        .curve(d3.curveLinearClosed); // straight lines and closed shape
    },
  },
  methods: {
    createChart() {
      this.svg = d3.select(this.$refs.svg).append("g");

      this.gridContainer = this.svg.append("g").attr("class", "grid-container");

      this.shapeContainer = this.svg
        .append("g")
        .attr("class", "shape-container");

      this.createGrid();
      this.createShape();
    },
    createGrid() {
      this.svg.attr(
        "transform",
        `translate(${this.width / 2}, ${this.height / 2})`
      );

      this.gridContainer.selectAll("g").remove();

      // draw connecting lines between axes
      const gridLines = this.gridContainer
        .append("g")
        .attr("class", "grid-lines");

      // for each level
      for (let indexLevel = 1; indexLevel <= this.levels.length; indexLevel++) {
        gridLines
          .append("g")
          .attr("class", "level")
          .selectAll("line")
          .data(this.data) // draw a line for each axis
          .join("line")
          .attr("x1", (d, indexAxis) =>
            this.getXPositionOnCircle(
              this.r((this.max / this.levels.length) * indexLevel),
              this.angle * indexAxis
            )
          )
          .attr("y1", (d, indexAxis) =>
            this.getYPositionOnCircle(
              this.r((this.max / this.levels.length) * indexLevel),
              this.angle * indexAxis
            )
          )
          .attr("x2", (d, indexAxis) =>
            this.getXPositionOnCircle(
              this.r((this.max / this.levels.length) * indexLevel),
              this.angle * (indexAxis + 1)
            )
          )
          .attr("y2", (d, indexAxis) =>
            this.getYPositionOnCircle(
              this.r((this.max / this.levels.length) * indexLevel),
              this.angle * (indexAxis + 1)
            )
          )
          .attr("stroke", this.gridColor)
          .attr("stroke-width", 1)
          .attr("opacity", 0.6);
      }

      const axis = this.gridContainer
        .append("g")
        .attr("class", "axes")
        .selectAll(".axis")
        .data(this.data)
        .join("g")
        .attr("class", "axis");

      // add labels
      const label = axis
        .append("text")
        .attr("x", (d, i) =>
          this.getXPositionOnCircle(
            this.r(this.max * this.labelFactor),
            this.angle * i
          )
        )
        .attr("y", (d, i) =>
          this.getYPositionOnCircle(
            this.r(this.max * this.labelFactor),
            this.angle * i
          )
        )
        .attr("font-size", "0.8rem")
        .attr("text-anchor", (d, i) => {
          const x = this.getXPositionOnCircle(
            this.r(this.max * this.labelFactor),
            this.angle * i
          );

          if (x > 0) return "start";
          if (x < 0) return "end";
          else return "middle";
        })
        .attr("fill", this.labelColor)
        .attr("dy", "0.35em");

      const that = this;

      label.each(function(d, i) {
        const parts = d.name.split(" ");
        if (parts.length === 1) {
          d3.select(this).text((d) => `${d.name} (${d.value})`);
        } else {
          const text = d3.select(this);

          parts.forEach((part, j) => {
            text
              .append("tspan")
              .attr(
                "x",
                that.getXPositionOnCircle(
                  that.r(that.max * that.labelFactor),
                  that.angle * i
                )
              )
              .attr("dy", j ? 12 : -12)
              // .attr("dx", j === parts.length - 1 ? 0 : -27)
              .text(
                (d) => `${part}${j === parts.length - 1 ? ` (${d.value})` : ""}`
              );
          });
        }
      });

      // .text((d) => d.name);

      // draw lines from center outwards for each axis
      axis
        .append("line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", (d, i) =>
          this.getXPositionOnCircle(this.r(this.max), this.angle * i)
        )
        .attr("y2", (d, i) =>
          this.getYPositionOnCircle(this.r(this.max), this.angle * i)
        )
        .attr("stroke", this.gridColor)
        .attr("stroke-width", 1.8);

      // add level labels to first axis
      d3.select(".axis")
        .append("g")
        .selectAll("text")
        .data(this.levels)
        .join("text")
        .attr("text-anchor", "end")
        .attr("dx", "-2")
        .attr("x", (d, i) =>
          this.getXPositionOnCircle(
            this.r((this.max / this.levels.length) * (i + 1)),
            0
          )
        )
        .attr("y", (d, i) =>
          this.getYPositionOnCircle(
            this.r((this.max / this.levels.length) * (i + 1)),
            0
          )
        )
        .attr("font-size", "8px")
        .attr("fill", this.labelColor)
        .attr("font-weight", 700)
        .attr("opacity", 0.7)
        .text((d) => d + this.unit);
    },
    createShape() {
      // transition config
      const t = d3.transition().duration(500);

      // draw dots
      this.shapeContainer
        .selectAll("circle")
        .data(this.data, (d) => d.name)
        .join(
          (enter) =>
            enter
              .append("circle")
              .attr("class", "shape")
              .attr("r", 3.5)
              .attr("fill", this.shapeColor)
              .attr("cx", 0)
              .attr("cy", 0)
              .transition(t)
              .attr("cx", (d, i) =>
                this.getXPositionOnCircle(this.r(d.value), this.angle * i)
              )
              .attr("cy", (d, i) =>
                this.getYPositionOnCircle(this.r(d.value), this.angle * i)
              ),
          (update) =>
            update
              .transition(t)
              .attr("cx", (d, i) =>
                this.getXPositionOnCircle(this.r(d.value), this.angle * i)
              )
              .attr("cy", (d, i) =>
                this.getYPositionOnCircle(this.r(d.value), this.angle * i)
              )
        );

      // draw shape
      this.shapeContainer
        .selectAll("path")
        .data([this.data], (d) => d) // store data in array to join the complete dataset with the path element
        .join(
          (enter) =>
            enter
              .append("path")
              .attr("class", "shape")
              .attr("stroke", this.shapeColor)
              .attr("stroke-width", 2)
              .attr("fill", this.shapeColor)
              .attr("fill-opacity", 0.6)
              .attr("d", (d) => {
                // set values to 0 to start transition from the center
                const startData = d.map((e) => {
                  return {
                    ...e,
                    value: 0,
                  };
                });
                return this.radialLine(startData);
              })
              .transition(t)
              .attr("d", (d) => this.radialLine(d)),
          (update) => update.transition(t).attr("d", (d) => this.radialLine(d))
        );
    },
    setSize() {
      const container = this.$refs.container;
      if (container) {
        const width = container.clientWidth;
        const height = container.clientHeight;
        const min = Math.min(width, height);
        this.width = min;
        this.height = min;
      }
    },
    resize() {
      const currentWidth = this.width;
      const currentHeight = this.height;
      this.setSize();
      if (currentWidth !== this.width || currentHeight !== this.height) {
        this.updateChart();
      }
    },
    updateChart() {
      d3.select(this.$refs.svg).attr("viewBox", [
        0,
        0,
        this.width,
        this.height,
      ]);
      this.createGrid();
      this.createShape();
    },
    // https://math.stackexchange.com/questions/676249/calculate-x-y-positions-in-circle-every-n-degrees
    getXPositionOnCircle(radius, angle) {
      return radius * Math.sin(angle + this.rotation);
    },
    getYPositionOnCircle(radius, angle) {
      return radius * -Math.cos(angle + this.rotation); // y -> -cos for inverted y-axis
    },
  },
  mounted() {
    this.setSize();
    this.createChart();

    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      this.resizeObserver.observe(this.$refs.container);
    } else {
      // for IE
      window.addEventListener("resize", this.resize);
    }
  },
  beforeDestroy() {
    if (window.ResizeObserver) {
      this.resizeObserver.unobserve(this.$refs.container);
    } else {
      // for IE
      window.removeEventListener("resize", this.resize);
    }
  },
  watch: {
    data() {
      this.updateChart();
    },
  },
};
</script>

<style scoped></style>
